import React, { useState } from 'react';
import styled from 'styled-components';
import { Download } from '../../components/Icons';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const ShowcaseSection = ({ data }) => {
  // const { images, link, id, attributes } = data;
  const { image, link, id, attributes } = data;
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0);

  function handleClickItem(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrentImageIndex(index);
  }

  function handleClickDownload() {
    const elem = document.createElement('a');
    const currentImage = images[currentImageIndex];
    elem.href = currentImage.url;
    elem.download = `${id}_${currentImage.name}`;
    elem.click();
  }

  return (
    <Root>
      <div className="container">
        <div className="left">
          <MobileHead>
            <div className="id">
              <span>TOKEN ID</span>
              <p>{id}</p>
            </div>
            <a href={link} target="_blank">VIEW ON OPENSEA</a>
          </MobileHead>
          <Photo>
            {/* <img src={images[currentImageIndex].url} alt="" /> */}
            <img src={image} alt="" />
          </Photo>
          {/* <SwitchBlock>
            <div className="buttonbar">
              { images.map((item, i) => 
                <button key={i}
                  className={currentImageIndex === i ? 'active' : ''}
                  data-index={i}
                  onClick={handleClickItem}
                >{item.name}</button>
              ) }
            </div>
            <button className="download" onClick={handleClickDownload}><Download /></button>
          </SwitchBlock> */}
        </div>
        <div className="right">
          <Info>
            <div className="head">
              <div className="id">
                <span>TOKEN ID</span>
                <p>#{id}</p>
              </div>
              <a href={link} target="_blank">VIEW ON OPENSEA</a>
            </div>
            <AttributeList>
              { attributes.map((attr, i) =>
                <li key={i}>
                  <div className="item">
                    <span>{ attr.trait_type }</span>
                    <span>{ attr.value }</span>
                    {/* <span>{ attr.rare }%</span> */}
                  </div>
                </li>
              ) }
            </AttributeList>
          </Info>
        </div>
      </div>
    </Root>
  )
}

const Root = styled.div`
  padding-top: 120px;
  padding-bottom: 80px;
  ${respondTo.md} {
    padding-top: 80px;
    padding-bottom: 15px;
  }
  > .container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
    width: 1100px;
    max-width: 100%;
    box-sizing: border-box;
    ${respondTo.md} {
      display: block;
      
    }
    > .right {
      width: 60%;
      ${respondTo.md} {
        width: 100%;
      }
    }
    > .left {
    }
  }
`

const MobileHead = styled.div`
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
  font-family: 'Economica';
  color: ${colors.blue};
  line-height: 1;

  display: none;
  ${respondTo.md} {
    display: flex;
  }
  > .id {
    span { font-size: 16px; }
    p { font-size: 48px; }
  }
  > a {
    display: inline-block;
    border-radius: 4px;
    padding: 4px 18px;
    font-family: 'Economica';
    font-size: 16px;
    line-height: 1;
    color: ${colors.blue};
    background-color: ${colors.brightBlue};
  }
`

const Photo = styled.div`
  border-radius: 24px;
  width: 380px;
  overflow: hidden;
  ${respondTo.md} {
    width: 100%;
  }
  > img {
    display: block;
    width: 100%;
  }
`
const SwitchBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0 12px;
  ${respondTo.md} {
    display: none;
  }
  .buttonbar {
    > button {
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 4px 18px;
      font-family: 'Economica';
      font-size: 16px;
      line-height: 1;
      color: ${colors.blue};
      background-color: ${colors.brightBlue};
      &.active {
        color: ${colors.white};
        background-color: ${colors.blue};
      }
      & + button {
        margin-left: 8px;
      }
    }
  }
  .download {
    border: 1px solid ${colors.lightBlue};
    border-radius: 4px;
    padding: 4px 8px;
    font-family: 'Economica';
    font-size: 16px;
    line-height: 1;
    color: ${colors.lightBlue};
    background-color: transparent;
  }
`

const Info = styled.div`
  ${respondTo.md} {
    padding-top: 20px;
    padding-bottom: 200px;
  }
  > .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 48px;
    font-family: 'Economica';
    color: ${colors.blue};
    line-height: 1;
    ${respondTo.md} {
      display: none;
    }
    > .id {
      span { font-size: 18px; }
      p { font-size: 90px; }
    }
    > a {
      display: inline-block;
      border-radius: 4px;
      padding: 4px 18px;
      font-family: 'Economica';
      font-size: 16px;
      line-height: 1;
      color: ${colors.blue};
      background-color: ${colors.brightBlue};
    }
  }
`

const AttributeList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  > li {
    margin-bottom: 12px;
    width: 50%;
    padding: 0 14px;
    box-sizing: border-box;
    ${respondTo.md} {
      width: 100%;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    border: 1px solid ${colors.blue};
    border-radius: 6px;
    padding: 4px 6px;
    font-size: 12px;
    font-family: 'Inter';
    color: ${colors.blue};
  }
`

export default ShowcaseSection;