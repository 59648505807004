import React, { useState } from 'react';
import styled from 'styled-components';
import { Title, Text } from '../../components/Typography';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const GallerySection = () => {
  const wording = _w('homepage.gallery');
  const [ current, setCurrent ] = useState(0);

  function handleClickPhoto(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrent(index);
  }
  
  return (
    <Root>
      <Heading>
        <Background>
          <img src="/images/homepage-about-bg.svg" alt="" />
        </Background>
        <Container>
          <Title className="title">{ wording.title }</Title>
          <Content className="content">{ wording.content }</Content>
        </Container>
      </Heading>
      <PhotoBox>
        { wording.photos.map((item, i) =>
          i === current && <PhotoItem key={i}><img src={item.url} alt={item.alt} /></PhotoItem>
        ) }
      </PhotoBox>
      <Photos>
        { wording.photos.map((item, i) =>
          <li key={i} data-index={i} onClick={handleClickPhoto}>
            <PhotoItem><img src={item.url} alt={item.alt} /></PhotoItem>
          </li>
        ) }
      </Photos>
    </Root>
  )
}

const Root = styled.div`
  padding: 160px 0;
  margin-bottom: 120px;
  ${respondTo.md} {
    padding-top: 60px;
    padding-bottom: 0;
    width: 100vw;
    overflow: hidden;
  }
`

const Heading = styled.div`
  position: relative;
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  ${respondTo.md} {
    width: 284px;
  }
`

const Content = styled(Text)`
  white-space: break-spaces;
  color: ${colors.darkBlue};
  ${respondTo.md} {
    text-align: justify;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  > img {
    width: 100%;
    height: auto;
  }
`

const PhotoBox = styled.div`
  margin: 0 auto;
  margin-top: 36px;
  padding: 0 20px;
  box-sizing: border-box;
  display: none;
  ${respondTo.md} {
    display: block;
  }
`
const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 95px;
  width: 1100px;
  max-width: 100%;
  ${respondTo.md} {
    margin-top: 4px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  > li {
    list-style: none;
    padding: 16px 12px;
    width: 33.33%;
    box-sizing: border-box;
    ${respondTo.md} {
      padding: 4px;
    }
  }
`
const PhotoItem = styled.div`
  border-radius: 15px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
`

export default GallerySection;