import AWS from 'aws-sdk';
import Web3 from 'web3/dist/web3.min.js'
import { contract_address } from '../contracts';

const web3 = new Web3(Web3.givenProvider);
const contractABI = require('../contracts/contract-abi.json');

const awsConfig = {
  "region": process.env['REACT_APP_AWS_REGION'],
  "endpoint": process.env['REACT_APP_AWS_ENDPOINT'],
  "accessKeyId": process.env['REACT_APP_AWS_ACCESS_KEY_ID'],
  "secretAccessKey": process.env['REACT_APP_AWS_SECRET_ACCESS_KEY'],
};

export async function getWhiteListIdentify() {
  const currentWalletAddress = window.ethereum.selectedAddress;

  return new Promise(resolve => {
    resolve(null)
    // AWS.config.update(awsConfig);
    // var docClient = new AWS.DynamoDB.DocumentClient();
    // var params = {
    //   TableName: "diamondTicketsWhitelist",
    //   FilterExpression: "address = :a",
    //   ExpressionAttributeValues: { ":a": currentWalletAddress.toLowerCase() }
    // };
    // docClient.scan(params, onScan);
  
    // function onScan(err, data) {
    //   if (err) {
    //     resolve(null);
    //   } else {        
    //     const result = data.Items[0];
        
    //     resolve(result);

    //     // continue scanning if we have more items
    //     if (typeof data.LastEvaluatedKey != "undefined") {
    //       resolve(null);
    //     }
    //   }
    // }
  })  
}

export async function getWhiteListLeftQty(qty) {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const maxQty = await contract.methods['whitelistMintedAmount'](currentWalletAddress).call()
  
  return Number(qty) - Number(maxQty);
}

export async function getPublicMintPrice() {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['mintPrice']().call()
  
  return Number(web3.utils.fromWei(result));
  
}

export async function getIsSoldOut() {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  const alreadyMintAmount = await contract.methods['totalMinted']().call()
  const total = await contract.methods['maxTicketAmount']().call()

  return {
    total: Number(total),
    alreadyMint: Number(alreadyMintAmount),
    isSoldOut: Number(alreadyMintAmount) == Number(total)
  };
}