import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useConnectWallet from '../../utils/useConnectWallet';
import { Metamask } from '../Icons';
import { colors } from '../../constants/colors';
// import { getGoldIdentity, getWhiteListIdentity } from '../../utils/getSpecialIdentity';
import { getWhiteListIdentify } from '../../utils/getWhiteListIdentify';
// import { getWhiteListIdentity } from '../../utils/getSpecialIdentity';

const Ticket = ({ msg_gold, msg_whitelist, ...props }) => {
  const [ isWhiteListIdenity, setIsWhiteListIdenity ] = useState(false);
  const [ isGoldListIdenity, setIsGoldListIdenity ] = useState(false);
  const { status, walletDisplay, walletAddress, onConnect, isNotFound } = useConnectWallet();

  useEffect(() => {
    window.ethereum?.on('accountsChanged', function (accounts) {
      if (status === 'connected') {
        window.location.reload();
      }
    })
  }, [walletAddress])
  
  async function handleClickWallet() {
    if (isNotFound) return;
    if (status === 'disconnected') {
      await onConnect();
      
      // const { status: isInGoldList } = await getGoldIdentity();
      const result = await getWhiteListIdentify();
      if (result) {
        setIsWhiteListIdenity(true);
      }
      else {
        setIsWhiteListIdenity(false);
      }
      // setIsGoldListIdenity(isInGoldList);

    };
  }

  useEffect(async () => {
    // const { status: isInGoldList } = await getGoldIdentity();
    const result = await getWhiteListIdentify();
    if (result) {
      setIsWhiteListIdenity(true);
    }
    else {
      setIsWhiteListIdenity(false);
    }
    // setIsGoldListIdenity(isInGoldList);
  }, [])
  
  
  return (
    <Root onClick={handleClickWallet} disabled={isNotFound} {...props}>
      { (status === 'connected' && isWhiteListIdenity && !isGoldListIdenity) &&
        <Dialog className="dialog" content={msg_whitelist} />
      }
      { (status === 'connected' && isGoldListIdenity) &&
        <Dialog className="dialog" content={msg_gold} gold={status === 'connected' && isGoldListIdenity} />
      }
      <Card gold={status === 'connected' && isGoldListIdenity}>
        { (isGoldListIdenity && status === 'connected') ?
          <img src="/images/ticket-sp.svg" alt="" />
          :
          <img src="/images/ticket.svg" alt="" />
        }
        { status === 'disconnected' && 
          <Cover>
            <Metamask />
            <span>CONNECT WALLET</span>
          </Cover>
        }
        { status === 'connected' &&
          <Info gold={isGoldListIdenity}>
            <div className="info-1">
              <div className="title">PASSENGER ID</div>
              <div className="content">{ walletDisplay }</div>
            </div>
            <div className="info-2">
              <div className="from">
                <span>FROM</span>
                <span>WEB3</span>
              </div>
              <Arrow />
              <div className="to">
                <span>TO</span>
                <span>COZY</span>
              </div>
            </div>
          </Info>
        }
      </Card>
    </Root>
  )
}

const Arrow = () => {
  return (
    <svg width="39" height="5" viewBox="0 0 39 5" fill={colors.blue}>
      <rect y="2" width="38" height="1" />
      <path d="M39 2.5L36.75 4.66506L36.75 0.334936L39 2.5Z" />
    </svg>
  )
}



const Dialog = ({ content, ...props }) => {
  return (
    <DialogRoot dangerouslySetInnerHTML={{__html: content}} {...props} >
    </DialogRoot>
  )
}

const Root = styled.div`
  position: relative;
  cursor: pointer;
  .dialog {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 24px);
    transform: translateX(-50%);
    white-space: nowrap;
  }
`

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  width: 70%;
  height: 100%;
  color: ${colors.blue};
  text-align: center;
  span {
    display: block;
    font-family: "Economica";
  }
`

const Card = styled.div`
  position: relative;
  filter: drop-shadow(2px 3px 0px ${colors.blue});
  ${({ gold }) => gold && css`
    filter: drop-shadow(2px 3px 0px #FFD47F);
  `}
  transition: all .3s ease;
  svg {
    opacity: 0.6;
    transition: all .3s ease;
  }
  &:hover {
    transform: rotate(-8deg);
    filter: drop-shadow(4px 6px 0px ${colors.blue});
    ${({ gold }) => gold && css`
      filter: drop-shadow(4px 6px 0px #FFD47F);
    `}
    svg {
      opacity: 1;
    }
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px 16px 16px 16px;
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  .info-1 {
    text-align: center;
    .title {
      line-height: 1;
      font-family: "Economica";
      font-size: 14px;
      color: ${colors.blue};
      ${({ gold }) => gold && css`
        color: #FFD47F;
      `}
    }
    .content {
      line-height: 1;
      font-family: "Inter";
      font-size: 12px;
      color: ${colors.darkBlue};
      transform: scale(0.8);
      ${({ gold }) => gold && css`
        color: #FFD47F;
      `}
    }
  }
  .info-2 {
    display: flex;
    justify-content: space-between;
    > svg {
      margin-top: 6px;
    }
    .from, .to {
      span:first-child {
        display: block;
        line-height: 1;
        font-family: "Economica";
        font-size: 16px;
        text-align: center;
        color: ${colors.blue};
        ${({ gold }) => gold && css`
          color: #FFD47F;
        `}
      }
      span:last-child {
        display: block;
        line-height: 1;
        font-family: "Inter";
        font-size: 12px;
        color: ${colors.darkBlue};
        transform: scale(0.8);
        ${({ gold }) => gold && css`
          color: #FFD47F;
        `}
      }      
    }
  }
`

const DialogRoot = styled.div`
  border-radius: 16px;
  padding: 4px 14px;
  background-color: #00BDE3;
  font-family: "Economica";
  color: ${colors.white};
  z-index: 1;
  filter: drop-shadow(-0.5px -0.5px 0px #00BDE3)
          drop-shadow(0.5px -0.5px 0px #00BDE3)
          drop-shadow(-0.5px 0.5px 0px #00BDE3)
          drop-shadow(0.5px 0.5px 0px #00BDE3);
  ${({ gold }) => gold && css`
  background-color: #FFD47F;
  color: #1A3448;
  filter: drop-shadow(-0.5px -0.5px 0px #FFD47F)
          drop-shadow(0.5px -0.5px 0px #FFD47F)
          drop-shadow(-0.5px 0.5px 0px #FFD47F)
          drop-shadow(0.5px 0.5px 0px #FFD47F);

  `};
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 3px 0 3px;
    border-color: #00BDE3 transparent transparent transparent;
    transform: translateX(-50%);
    ${({ gold }) => gold && css`
      border-color: #FFD47F transparent transparent transparent;
    `};
  }
  a {
    text-decoration: underline;
  }
`

export default Ticket;