import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Monitor from './Monitor';
import { openseaUrl, nftPrice, total, discordUrl } from '../../contracts';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import useConnectWallet from '../../utils/useConnectWallet';
import { mintNFT } from '../../utils/mintNFT';
import { _w } from '../../utils/wordingSystem';
import { detectGoldenListEnable, detectWhiteListEnable, getMaxMintAmount, getPaused, getStockAmount, getAlreadyMintInWhiteList, getAlreadyMintInGoldenList } from './MintableStatus';
import { getGoldIdentity, getWhiteListIdentity } from '../../utils/getSpecialIdentity';
import Dialog from '../../components/Dialog';

const MintPage = () => {
  const wording = _w('setting.mint');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPause, setIsPause ] = useState(false);
  const [ isSoldOut, setIsSoldOut ] = useState(false);
  const [ merkleTreeValue, setMerkleTreeValue ] = useState(null);
  const [ mintStep, setMintStep ] = useState('public');
  const [ allowMintAmout, setAllowMintAmount ] = useState(0);
  const [ mintAmout, setMintAmount ] = useState(0);
  const [ currentStatus, setCurrentStatus ] = useState('welcome');
  const { status: walletStatus, walletAddress, onConnect, isNotFound } = useConnectWallet();

  useEffect(() => {
    handleEntryStatus();
  }, [walletStatus])

  useEffect(() => {
    window.ethereum?.on('accountsChanged', function (accounts) {
      window.location.reload();
    })
  }, [walletAddress])
  
  async function handleDefinedMintableStatus() {
    setIsLoading(true);
    
    const stockAmount = await getStockAmount();

    if (total === stockAmount) {
      setIsSoldOut(true);
      setCurrentStatus('empty');
    }
    else {
      const isGoldenListEnable = await detectGoldenListEnable(walletAddress);
      const isWhiteListEnable = await detectWhiteListEnable(walletAddress);
      const maxMintAmount = await getMaxMintAmount(walletAddress);
      const isPause = await getPaused(walletAddress);
      const { status: isGoldIdentity, value: goldValue } = await getGoldIdentity(walletAddress);
      const { status: isWhiteListIdentity, value: whiteListValue } = await getWhiteListIdentity(walletAddress);
      
      if (isGoldenListEnable && isGoldIdentity) {
        const isAlreadyMintInGoldenList = await getAlreadyMintInGoldenList(walletAddress);
        if (isAlreadyMintInGoldenList) {
          setCurrentStatus('already_mint');
          setMintAmount(maxMintAmount);
        }
        else {
          setCurrentStatus('select');
          setMintStep('golden');
          setMerkleTreeValue(goldValue);
          setMintAmount(maxMintAmount);
          setAllowMintAmount(maxMintAmount);
        }
      }
      else if (isWhiteListEnable && isWhiteListIdentity) {
        const isAlreadyMintInWhiteList = await getAlreadyMintInWhiteList(walletAddress);
        if (isAlreadyMintInWhiteList) {
          setCurrentStatus('already_mint');
          setMintAmount(maxMintAmount);
        }
        else {
          setCurrentStatus('select');
          setMerkleTreeValue(whiteListValue);
          setMintStep('whitelist');
          setMintAmount(maxMintAmount);
          setAllowMintAmount(maxMintAmount);
        }
      }
      else if (!isPause) {
        setCurrentStatus('select');
        setMintStep('public');
        setMintAmount(maxMintAmount);
        setAllowMintAmount(maxMintAmount);
      }
      else {
        setIsPause(isPause);
        setCurrentStatus('empty');
      }
    }
    setIsLoading(false);
  }
  
  function handleEntryStatus() {
    setTimeout(() => {
      if (walletStatus === 'connected') {
        handleDefinedMintableStatus();
      }
      else if (walletStatus === 'disconnected') {
        setCurrentStatus('unconnected');
      }
    }, 600)
  }

  function handleClickNumber(e) {
    const number = Number(e.currentTarget.dataset.number);
    setMintAmount(number);
  }

  function renderMintSelector() {
    const selectors = [];
    for (let i = 1; i <= wording.max; i++) {
      selectors.push(
        <QtyButton
          key={'mint_selector_' + i}
          className={mintAmout === i ? 'active' : ''}
          data-number={i}
          onClick={handleClickNumber}>{i}</QtyButton>
      );
    }
    return selectors;
  }

  async function handleClickConnectButton() {
    if (isNotFound) return;
    if (walletStatus === 'disconnected') onConnect();
  }

  function handleClickLetMintButton() {
    setCurrentStatus('qty');
  }
  function handleClickBackButton() {
    setCurrentStatus('select');
  }
  async function handleClickOKButton() {
    if (walletStatus === 'connected') {
      const { status, message } = await mintNFT(mintStep, mintAmout, merkleTreeValue);
      if (status === 'succeed') {
        setCurrentStatus('succeed');
      }
      else if (status === 'failed') {
        setCurrentStatus('failed');
      }
    };
  }
  
  return (
    <Root>
      <Background>
        <img className="desktop" src="/images/mint-bg.png" alt="" />
        <img className="mobile" src="/images/mint-bg-m.png" alt="" />
      </Background>
      <Main>
        <MainComputer show={isSoldOut} src="/images/mint-sold_out.png" alt="" />
        <MainComputer className="cover" show={isPause && !isSoldOut} src="/images/mint-pause.png" alt="" />
        <MainComputer className="cover" show={!isSoldOut && !isPause} src="/images/mint-main.png" alt="" />

        { isPause &&
          <PauseDialog arrow="left" className="dialog" content={wording.out_of_order} />
        }
        <Monitor
          data={wording[currentStatus]}
          mintMax={allowMintAmout}
          mintPrice={nftPrice}
          mintAmout={mintAmout}
          clickNumber={handleClickNumber} />
        <Ticket active={currentStatus === 'succeed'}>
          <img src="/images/mint-ticket.png" alt="" />
        </Ticket>
        <ButtonBar>
          { currentStatus === 'unconnected' &&
            <Button onClick={handleClickConnectButton}>
              { !isLoading ? 'CONNECT WALLET' : 'CONNECTING...'}
            </Button>
          }
          { currentStatus === 'select' &&
            <Button onClick={handleClickLetMintButton}>OK</Button>
          }
          { currentStatus === 'qty' &&
            <>
              <Button onClick={handleClickBackButton}>BACK</Button>
              <Button onClick={handleClickOKButton}>MINT</Button>
            </>
          }
          { currentStatus === 'succeed' &&
            <>
              {/* <Button as={Link} to="collection">VIEW MY COZIES</Button> */}
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { currentStatus === 'failed' &&
            <>
              <Button onClick={handleClickBackButton}>TRY AGAIN</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { (isPause && !isSoldOut) &&
            <>
              <Button as="a" href={discordUrl} target="_blank">JOIN DISCORD</Button>
            </>
          }
          { isSoldOut &&
            <>
              <Button disabled>SOLD OUT</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
        </ButtonBar>
      </Main>
      <MobileButtonBar>
        { currentStatus === 'unconnected' &&
            <Button onClick={handleClickConnectButton}>
              { !isLoading ? 'CONNECT WALLET' : 'CONNECTING...'}
            </Button>
          }
          { currentStatus === 'select' &&
            <>
              <div className="amount">
                { renderMintSelector() }
              </div>
            <Button onClick={handleClickLetMintButton}>OK</Button>
            </>
          }
          { currentStatus === 'qty' &&
            <>
              <Button onClick={handleClickBackButton}>BACK</Button>
              <Button onClick={handleClickOKButton}>MINT</Button>
            </>
          }
          { currentStatus === 'succeed' &&
            <>
              <Button as={Link} to="collection">VIEW MY COZIES</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { currentStatus === 'failed' &&
            <>
              <Button onClick={handleClickBackButton}>TRY AGAIN</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { (isPause && !isSoldOut) &&
            <>
              <Button as="a" href={discordUrl} target="_blank">JOIN DISCORD</Button>
            </>
          }
          { isSoldOut &&
            <>
              <Button disabled>SOLD OUT</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
      </MobileButtonBar>
    </Root>
  )
}

const Root = styled.div`
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`


const Background = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  > img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
  .desktop { display: block; }
  .mobile { display: none; }
  ${respondTo.md} {
    .desktop { display: none; }
    .mobile { display: block; }
  }
`

const Main = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 750px;
  transform: translate(-50%, -50%);
  ${respondTo.md} {
    top: 40%;
    width: 100%;
  }
`

const MainComputer = styled.img`
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  &.cover {
    position: absolute;
    top: 0;
    left: 0;
  }
  ${({ show }) => show && css`
    opacity: 1;
    visibility: visible;
  `}
`

const Ticket = styled.div`
  position: absolute;
  top: 67.5%;
  left: 50%;
  width: 10%;
  transform: translateX(-50%);
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transform: translateY(-100%);
    transition: all 1s ease;
    ${({ active }) => active && css`
      transform: translateY(0%);
    `}
  }
`

const Button = styled.button`
  border: 1px solid ${colors.blue};
  border-radius: 24px;
  padding: 8px;
  width: 190px;
  font-family: 'Economica';
  font-size: 24px;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  color: ${colors.blue};
  background: ${colors.white};
  & + a, & + button {
    margin-top: 12px;
  }
  ${({ disabled }) => disabled && css`
    background: rgba(105, 167, 202, .6);
    color: ${colors.white};
    pointer-events: none;
  `}
`

const QtyButton = styled.button`
  margin: 0 5px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  width: 42px;
  height: 42px;
  font-family: 'Economica';
  font-size: 24px;
  color: ${colors.white};
  background: ${colors.blue};
  &.active {
    color: ${colors.blue};
    background: ${colors.white};
  }
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 10%;
  margin: 0 12px;
  transform: translateX(-50%);
  ${respondTo.md} {
    display: none;
  }
`

const Temp = styled.div`
  position: absolute;
  left: 12px;
  bottom: 12px;
`

const MobileButtonBar = styled.div`
  position: absolute;
  left: 0;
  bottom: 10%;
  text-align: center;
  width: 100%;

  display: none;
  ${respondTo.md} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  > .amount {
    margin-bottom: 24px;
  }
`

const PauseDialog = styled(Dialog)`
  position: absolute;
  right: 6%;
  top: 30%;
  z-index: 2;
  font-size: 18px;
  ${respondTo.sm} {
    top: 8%;
    right: 8%;
    padding: 8px 16px;
  }
`


export default MintPage;