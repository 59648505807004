export const nftPrice = 0
export const contract_address = "0xB26920e53404e794cb29B39d286410fab3ad2DDD"
export const mint_function = "mint"
export const own_function = "tokensOfOwner"
export const contractChainId = ""
export const max_mint = 6;
export const total = 10000
export const metamaskAppUrl = "https://metamask.app.link/dapp/"
export const openseaUrl = "https://opensea.io/collection/journeywithcozies"
export const discordUrl = "https://discord.gg/5umNfcab6R"

export const dutch_auction = {
  duration: {
    // start: "Oct 11 2022 22:20:00 UTC-4",
    start: "Oct 12 2022 00:00:00 GMT+0800",
    // end: "Oct 11 2022 23:50:00 UTC-4"
    end: "Oct 30 2022 00:00:00 GMT+0800"
  },
  discount: 0.000135,
  time_step: 5
}