import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { total } from '../../contracts';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { getStockAmount } from '../MintPage/MintableStatus';

const CountdownSection = ({...props}) => {
  const timerRef = useRef(null);
  const endTimeRef = useRef(null);
  const [ count, setCount ] = useState(null);
  const { title, datetime } = _w('setting.countdown');

  const [ isSoldOut, setIsSoldOut ] = useState(false);

  useEffect(async() => {
    const stockAmount = await getStockAmount();
    if (total === stockAmount) {
      setIsSoldOut(true);
    }
  }, [])
  
  useEffect(() => {
    const currentTimeWithGMT = new Date().toUTCString();
    const currentTime = new Date(currentTimeWithGMT).getTime();

    const endTimeWithGMT = new Date(datetime).toUTCString();
    const endTime = new Date(endTimeWithGMT).getTime();

    endTimeRef.current = endTime;
    timerRef.current = setInterval(handleUpdateTime, 1000);
    if (currentTime > endTime) {
      clearInterval(timerRef.current)
    }
    return () => clearInterval(timerRef.current);
  }, [])

  function handleUpdateTime() {
    const now = new Date().getTime();
    setCount(endTimeRef.current - now)
  }
  
  return (
    <Root {...props}>
      <Wrapper>
        <Timer>
          { isSoldOut ?
            <span className="title">SOLD OUT</span>
            :
            <>
              <span className="title">{ title }</span>
              { count ? <Clock unit="days" count={count} /> : '00' }
              <span>:</span>
              { count ? <Clock unit="hours" count={count} /> : '00' }
              <span>:</span>
              { count ? <Clock unit="minutes" count={count} /> : '00' }
              <span>:</span>
              { count ? <Clock unit="seconds" count={count} /> : '00' }
            </>
          }
          
        </Timer>
      </Wrapper>
    </Root>
  )
}

const Clock = ({ count, unit }) => {
  function numberTransfer(num) {
    const length = (num + '').length;
    let str = Math.max(num, 0);
    if (length === 1) {
      str = `0${num}`;
    }
    return str;
  }
  
  return (
    <ClockRoot>
      <div className="counter">
        { unit === 'days' && numberTransfer(Math.floor(count / (1000 * 60 * 60 * 24))) }
        { unit === 'hours' && numberTransfer(Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))) }
        { unit === 'minutes' && numberTransfer(Math.floor((count % (1000 * 60 * 60)) / (1000 * 60))) }
        { unit === 'seconds' && numberTransfer(Math.floor((count % (1000 * 60)) / 1000)) }
      </div>
    </ClockRoot>
  )
}

const Root = styled.div`
  position: absolute;
  top: 12vh;
  right: 0;
  z-index: 1;
`

const Wrapper = styled.div`
  position: relative;
  border-radius: 24px;
  transform: translateX(-24px);
  &:before, &:after {
    content: "";
    position: absolute;
    border: 1px solid rgba(182, 210, 226, 1);
    left: 100%;
    width: 24px;
    height: 4px;
    background-color: #D9EBF1;
    transform: translateY(-50%);
    z-index: -1;
  }
  &:before {
    top: 33%;
  }
  &:after {
    top: 66%;
  }
`
const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 24px;
  padding: 7px 14px;
  font-family: 'Economica';
  font-size: 24px;
  line-height: 1;
  color: ${colors.blue};
  background-color: ${colors.white};
  z-index: 1;
  box-shadow: 0px 0px 0px 1px rgba(182, 210, 226, 1),
              0px 0px 0px 8px #D9EBF1,
              0px 0px 0px 9px rgba(182, 210, 226, 1);
  .title {
    margin-right: 12px;
  }
  span {
    display: inline-block;
    margin: 0;
    ${respondTo.md} {
      margin: 0;
    }
  }
`

const ClockRoot = styled.div`
  text-align: center;
  box-sizing: border-box;
`

export default CountdownSection;