import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Dialog from '../../components/Dialog';
import { Title } from '../../components/Typography';
import { colors } from '../../constants/colors';
import { respondFrom, respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const FAQSection = () => {
  const wording = _w('train.faq');
  return (
    <Root>
      <Wrapper>
        <Title className="title">{ wording.title }</Title>
        <List>
          { wording.list.map((item, i) => 
            <li key={i}>
              <QuestionItem data={item} />
            </li>
          ) }
        </List>
        <Bottom>
          <Dialog className="dialog" content={wording.note} />
          <img className="background" src="/images/background-2.svg" alt="" />
          <img className="main" src={wording.bottom} alt={wording.bottom_alt} />
        </Bottom>
      </Wrapper>
    </Root>
  )
}

const QuestionItem = ({ data }) => {
  const { question, answer } = data;
  const [ active, setActive ] = useState(false);
  
  function handleClickButton() {
    setActive(!active);
  }
  
  return (
    <QuestionItemRoot active={active}>
      <button onClick={handleClickButton}>
        <img className="arrow" src="/images/arrow.svg" alt="" />
        <span>{ question }</span>
      </button>
      <p dangerouslySetInnerHTML={{__html: answer}} />
    </QuestionItemRoot>
  )
}

const Root = styled.div`
  position: relative;
  background-image: linear-gradient(180deg, ${colors.brightBlue} 0%, ${colors.brightBlue} 80%, ${colors.white} 100%);
  padding: 120px 0;
  ${respondTo.md} {
    padding: 0;
    background: ${colors.brightBlue};
  }
`

const Wrapper = styled.div`
  position: relative;
  border-radius: 36px;
  margin: 0 auto;
  width: 750px;
  max-width: 100%;
  background-image: linear-gradient(180deg, ${colors.lightBlue} 0%, ${colors.lightBlue} 80%, rgba(194, 224, 236, 0.6) 100%);
  box-sizing: border-box;
  ${respondTo.md} {
    border-radius: 0;
    width: 100%;
    text-align: left;
    background-image: linear-gradient(180deg, ${colors.lightBlue} 0%, ${colors.lightBlue} 80%, ${colors.brightBlue} 100%);
  }
  .title {
    padding: 60px 80px;
    padding-bottom: 0;
    margin-bottom: 24px;
    text-align: center;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0 80px;
  margin-top: 50px;
  width: 950px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0 24px;
  }
  > li {
    margin-bottom: 24px;
    ${respondTo.md} {
      margin-bottom: 12px;
    }
  }
`

const QuestionItemRoot = styled.div`
  text-align: left;
  border-radius: 24px;
  transition: all .3s ease;
  ${({ active }) => active && css`
    background-color: ${colors.brightBlue};
  `}
  ${respondFrom.md} {
    &:hover {
      background-color: ${colors.brightBlue};
    }
  }
  button {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 24px;
    padding: 16px 24px;
    width: 100%;
    height: auto;
    color: ${colors.blue};
    font-size: 24px;
    font-family: 'Economica';
    background-color: transparent;
    text-align: left;
    ${respondTo.md} {
      align-items: flex-start;
    }
    .arrow {
      margin-right: 18px;
      transition: transform .3s ease;
      ${respondTo.md} {
        margin-top: 8px;
      }
      ${({ active }) => active && css`
        transform: rotate(90deg);
      `}
    }
  }
  p {
    padding: 0 24px;
    padding-bottom: 0;
    margin-left: 24px;
    max-height: 0;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 1.3em;
    color: ${colors.darkBlue};
    overflow: hidden;
    transition: all .3s ease;
    box-sizing: border-box;
    white-space: break-spaces;
    a {
      text-decoration: underline;
    }
    ${respondTo.md} {
      margin-left: 0;
    }
    ${({ active }) => active && css`
      margin-top: 6px;
      padding-bottom: 24px;
      max-height: 100vh;
    `}
  }
`

const Bottom = styled.div`
  position: relative;
  margin-top: 60px;
  .background {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
  .main {
    display: block;
    position: relative;
    margin: auto;
    width: 350px;
    height: auto;
  }
  .dialog {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    ${respondTo.md} {
      top: -10%;
    }
  }
`

export default FAQSection;