import React from 'react';
import styled from 'styled-components';
import { Headphone, Metamask } from '../Icons';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';

const MobileBottomBar = () => {
  return (
    <Root>
      <Wrapper>
        <MusicButton>
          <Headphone />
        </MusicButton>
        <MintButton>MINT</MintButton>
        <WalletButton>
          <Metamask />
        </WalletButton>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`
  display: none;
  ${respondTo.md} {
    display: none;
  }
`
const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 66px;
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  z-index: 2;
`

const Button = styled.button`
  margin: 0 12px;
  padding: 0;
  border: 1px solid ${colors.blue};
  border-radius: 24px;
  height: 42px;
  background: transparent;
`

const MusicButton = styled(Button)`
  width: 42px;
  color: ${colors.blue};
  background-color: ${colors.white};
`

const MintButton = styled(Button)`
  width: calc(100% - 150px);
  background-color: ${colors.white};
  color: ${colors.blue};
  font-size: 24px;
  line-height: 0;
  font-family: "Economica";
`

const WalletButton = styled(Button)`
  width: 42px;
  border: 1px solid ${colors.white};
  color: ${colors.white};
  background-color: ${colors.blue};
  > svg {
    margin-top: 4px;
    width: 22px;
  }
`

export default MobileBottomBar;