import React from 'react';
import styled from 'styled-components';
import { Title, Text } from '../../components/Typography';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const AboutSection = () => {
  const wording = _w('homepage.about');
  return (
    <Root>
      <Background>
        <img className="bg" src="/images/homepage-about-bg.svg" alt="" />
      </Background>
      <Container>
        <img className="left" src={wording.item_1} alt={wording.item_1_alt} />
        <img className="right" src={wording.item_2} alt={wording.item_2_alt} />
        <Title className="title">{ wording.about_title }</Title>
        <Content dangerouslySetInnerHTML={{__html: wording.about_content}} />
        <br />
        <br />
        <Title className="title">{ wording.story_title }</Title>
        <Content dangerouslySetInnerHTML={{__html: wording.story_content}} />
      </Container>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: 160px 0;
  padding-bottom: 80px;
  ${respondTo.md} {
    padding: 120px 0;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  ${respondTo.md} {
    width: 300vw;
  }
  > .bg {
    width: 100%;
    height: auto;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  ${respondTo.md} {
    width: 284px;
    text-align: left;
  }
  > .left {
    position: absolute;
    bottom: 10vh;
    right: 100%;
    width: 300px;
    height: auto;
    ${respondTo.md} {
      width: 180px;
      bottom: auto;
      right: -30%;
      top: 100%;
    }
  }
  > .right {
    position: absolute;
    top: 40vh;
    left: 100%;
    width: 150px;
    height: auto;
    ${respondTo.md} {
      width: 100px;
      top: auto;
      bottom: 100%;
      left: -10%;
    }
  }
  .title {
    ${respondTo.md} {
      text-align: center;
    }
  }
`

const Content = styled(Text)`
  white-space: break-spaces;
  color: ${colors.darkBlue};
  ${respondTo.md} {
    text-align: justify;
  }
`


export default AboutSection;