import { contractChainId, contract_address } from '../../contracts';
import Web3 from 'web3/dist/web3.min.js'
import { ethers } from 'ethers';

const web3 = new Web3(Web3.givenProvider);
const contractABI = require('../../contracts/contract-abi.json');

export const getTickets = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['tokensOfOwner'](currentWalletAddress).call()

  return result;
};


export const exchangeNft = async (tickets) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);

  let status = '';
  let message = '';

  const currentContract = new web3.eth.Contract(contractABI, contract_address);
  
  const data = currentContract.methods['cutTicket'](tickets).encodeABI();

  let exchangePrice = await contract.methods['cutPrice']().call()
  // exchangePrice = web3.utils.fromWei(exchangePrice, 'ether')




  // const value = ethers.utils.hexlify(
  //   ethers.utils.parseEther((parseInt(tickets.length) * Number(exchangePrice)).toString())
  // ).slice(2).replace(/^0+/, '')

  // console.log({value, data});
  // console.log(ethers.utils.parseEther((parseInt(tickets.length) * Number(exchangePrice)).toString()))
  
  console.log(ethers.utils.parseEther((parseInt(tickets.length) * exchangePrice).toString()))
  console.log(tickets)
  console.log('price: ' + exchangePrice)
  console.log('parse: ' + parseInt(tickets.length))
  console.log('time: ' + (parseInt(tickets.length) * exchangePrice).toString())
  
  
  const finalPrice = (parseInt(tickets.length) * exchangePrice).toString()
  console.log(web3.utils.fromWei(finalPrice, 'ether').toString())

  const transactionParams = {
    to: contract_address,
    from: window.ethereum?.selectedAddress,
    value: ethers.utils.hexlify(
        ethers.utils.parseEther(web3.utils.fromWei(finalPrice, 'ether').toString())
      ).slice(2).replace(/^0+/, ''),
  
    // TODO: NFT mint method here
    data: data,
    chainId: contractChainId,
  }

  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParams],
    });

    // const { url } = chainMapping(contract_address);
    status = 'succeed';
    // message = `checkout out on ${url}tx/${txHash} later.`
    
  }
  catch (err) {
    status = 'failed';
    // message = `something went wrong: ${err.message}`;
  }

  return { status, message };
};


export const isExchangeEnable = async () => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  let startTime = await contract.methods['cutTicketStartTime']().call()
  startTime = Number(startTime);
  let endTime = await contract.methods['cutTicketEndTime']().call()
  endTime = Number(endTime);

  const blockNumber = await web3.eth.getBlockNumber();
  const blockStatus = await web3.eth.getBlock(blockNumber);
  const currentTime = blockStatus.timestamp
  
  return currentTime < endTime && currentTime > startTime;
}