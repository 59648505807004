import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';

const LinksList = ({ data, onClickLink, ...props }) => {
  return (
    <List {...props}>
      { data.map((link, i) =>
        link.enable &&
        <React.Fragment key={i}>
        { link.to &&
          <LinkItem
            type={link.type}
            onClick={onClickLink}
            to={link.to}>{ link.title }</LinkItem>
        }
        {
          link.href &&
          <LinkItem
            as="a"
            type={link.type}
            href={link.href}
            target="_blank">{ link.title }</LinkItem>
        }
        </React.Fragment>
      ) }
    </List>
  )
}

const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${respondTo.md} {
    display: block;
  }
`

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 4px 0px;
  min-width: 110px;
  height: 100%;
  line-height: 1;
  font-size: 16px;
  font-family: "Economica";
  transition: all .3s ease;
  & + a {
    margin-left: 30px;
  }
  ${({ type }) => type === 1 && css`
  background: rgba(194, 224, 236, .5);
  color: ${colors.blue};
  &:hover {
    border: 1px solid ${colors.blue};
  }
  `}
  ${({ type }) => type === 2 && css`
    color: ${colors.white};
    background: rgba(105, 167, 202, 0.6);
  `}
  ${respondTo.md} {
    display: block;
    margin: 0;
    border-radius: 25px;
    padding: 8px 0;
    width: 180px;
    height: auto;
    font-size: 24px;
    text-align: center;
    background-color: ${colors.white};
    & + a {
      margin: 0;
      margin-top: 32px;
    }
    ${({ type }) => type === 1 && css`
      border: 1px solid ${colors.lightBlue};
      background: transparent;
      color: ${colors.blue};
    `}
    ${({ type }) => type === 2 && css`
      background: rgba(105, 167, 202, 0.6);
      color: ${colors.white};
    `}

  }

  ${({ show }) => show === 'desktop' && css`
    display: none;
  `}

  ${ respondTo.md } {
    display: block;
    ${({ show }) => show === 'desktop' && css`
      display: block;
    `}
  }
`

export default LinksList;