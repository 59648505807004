import styled from "styled-components";
import { colors } from "../../constants/colors";
import { respondTo } from "../../utils/responsive";

const Title = styled.div`
  font-family: "Economica";
  font-size: 90px;
  color: ${colors.blue};
  ${respondTo.md} {
    font-size: 48px;
  }
`

const Text = styled.div`
  font-family: "Inter";
  font-size: 12px;
  line-height: 18px;;
  font-weight: 400;
`

export { Title, Text };