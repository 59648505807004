import React from 'react';
import styled from 'styled-components';
import HeadingSection from './HeadingSection';
import IntroSection from './IntroSection';
import AboutSection from './AboutSection';
import GallerySection from './GallerySection';
import SocialSection from './SocialSection';
import CountdownSection from './CountdownSection';
import MobileBottomBar from '../../components/MobileBottomBar';
import { respondTo } from '../../utils/responsive';
import Helmet from 'react-helmet';
import { _w } from '../../utils/wordingSystem';

const HomePage = () => {
  const wording = _w('homepage');
  return (
    <Root>
      <Helmet>
        <title>{wording.meta_title}</title>
      </Helmet>
      {/* <CountdownSection /> */}
      <HeadingSection />
      <IntroSection />
      <AboutSection />
      <GallerySection />
      <SocialSection />
      <MobileBottomBar />
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`

export default HomePage;