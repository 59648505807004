import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const HeadingSection = () => {
  const wording = _w('journey.heading');
  
  return (
    <Root>
      <Heading>
        <Background src={wording.background} />
        <Hint>
          <div className="wrap">
            <img src="/images/hint-arrow.svg" alt="" />
          </div>
        </Hint>
      </Heading>
    </Root>
  )
}


const Root = styled.div`
  position: relative;
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`
const Heading = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vh;
    background-image: linear-gradient(0deg, rgba(105, 167, 202, 0.8) 0%, rgba(105, 167, 202, 0) 80%, rgba(105, 167, 202, 0) 100%);
    z-index: 0;
  }
`

const floatAnimation = keyframes`
  from {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
`

const Background = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Hint = styled.div`
  position: absolute;
  left: 50%;
  bottom: 5vh;
  transform: translateX(-50%);
  color: ${colors.white};
  font-family: 'Economica';
  font-size: 30px;
  width: 100%;
  text-align: center;
  z-index: 1;
  ${respondTo.md} {
    bottom: 75px;
  }
  .wrap {
    animation: ${floatAnimation} 3s infinite ease;
  }
  span {
    display: block;
    margin-bottom: 16px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
`


export default HeadingSection;