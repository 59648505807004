import React from 'react';
import styled from 'styled-components';
import { Metamask, Twitter, Instagram, Discord, Linkedin } from '../../components/Icons';
import { Title, Text } from '../../components/Typography';
import Carousel from '../../components/Carousel';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const TeamSection = () => {
  const wording = _w('train.team');
  
  return (
    <Root>
      <Heading>
        <Background>
          <img src="/images/background-1.svg" alt="" />
        </Background>
        <Container>
          <Title>{ wording.title }</Title>
          <div className="content">{ wording.content }</div>
        </Container>
      </Heading>
      <TeamList>
        { wording.list.map((item, i) =>
          <li key={i} data-index={i}>
            <TeamItem data={item} />
          </li>
        ) }
      </TeamList>
      <CarouselWrapper>
        <Carousel
          controller={ false }
          setting={{
          centerMode: true,
          centerPadding: '48px',
        }}
        >
          { wording.list.map((item, i) =>
            <li key={i} data-index={i}>
              <TeamItem data={item} />
            </li>
          ) }
        </Carousel>
      </CarouselWrapper>
    </Root>
  )
}

const TeamItem = ({ data, ...props }) => {
  const { photo, photo_alt, title, name, links } = data;
  return (
    <TeamItemRoot {...props}>
      <div className="photo">
        <img src={photo} alt={photo_alt} />
      </div>
      <div className="content">
        <div className="title">{ title }</div>
        <div className="info">
          <div className="name">{ name }</div>
          <div className="links">
            { links.map((link, i) => 
              <LinkItem key={i} label={link.label} url={link.url} />
            ) }
          </div>
        </div>
      </div>
      <img className="edge" src="/images/ticket-edge.svg" alt="" />
    </TeamItemRoot>
  )
}

const LinkItem = ({ label, url, ...props }) => {
  return (
    <LinkItemRoot {...props} href={url} target="_blank">
      { label === 'linkedin' && <Linkedin /> }
      { label === 'matamask' && <Metamask /> }
      { label === 'twitter' && <Twitter /> }
      { label === 'instagram' && <Instagram /> }
      { label === 'discord' && <Discord /> }
    </LinkItemRoot>
  )
}

const Root = styled.div`
  position: relative;
  background-color: ${colors.brightBlue};
  padding: 120px 0;
  ${respondTo.md} {
    padding: 90px 0;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  ${respondTo.md} {
    margin-bottom: 60px;
    width: 284px;
  }
  .title {
    margin-bottom: 24px;
  }
  .content {
    color: rgba(119, 156, 176, 1);
    font-family: 'Economica';
    font-size: 30px;
    ${respondTo.md} {
      font-size: 24px;
    }
  }
`


const Heading = styled.div`
  position: relative;
`

const Background = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 0;
  overflow: hidden;
  ${respondTo.md} {
    top: auto;
    bottom: 100%;
    width: 150vw;
    transform: translate(-50%, 0%);
  }
  > img {
    width: 100%;
    height: auto;
  }
`

const TeamList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 95px;
  width: 950px;
  max-width: 100%;
  ${respondTo.md} {
    display: none;
  }
  ${respondTo.md} {
    margin-top: 4px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  > li {
    margin-bottom: 36px;
    padding: 16px 12px;
    width: 25%;
    box-sizing: border-box;
    ${respondTo.md} {
      padding: 4px;
    }
  }
`

const CarouselWrapper = styled.div`
  list-style: none;
  display: none;
  ${respondTo.md} {
    display: block;
  }
  li {
    margin-bottom: 36px;
    padding: 16px 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${respondTo.md} {
      padding: 0px 8px;
    }
  }
`


const TeamItemRoot = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding-bottom: 8px;
  border-radius: 4px 4px 0 0;
  background-color: ${colors.white};
  box-sizing: border-box;
  .photo {
    position: relative;
    border-radius: 4px 4px 0 0;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    > img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      transform-origin: 50% 5%;
      transform: scale(1.8);
      transition: all .3s ease;
      &:hover {
        transform-origin: 50% 50%;
        transform: scale(1.01);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .title {
      margin-bottom: 4px;
      padding: 16px;
      font-family: 'Economica';
      text-transform: uppercase;
      white-space: break-spaces;
      line-height: 1;
      font-size: 30px;
      color: ${colors.blue};
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
    }
    .name {
      font-family: 'Economica';
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 16px;
      color: ${colors.blue};
    }
    .links {
      display: flex;
      justify-content: flex-end;
      > a + a {
        margin-left: 6px;
        ${respondTo.lg} {
          margin-left: 2px;
        }
        ${respondTo.md} {
          margin-left: 8px;
        }
      }
    }
  }
  .edge {
    position: absolute;
    top: 99%;
    left: 0;
    width: 100%;
  }
`

const LinkItemRoot = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.blue};
  height: auto;
  opacity: 0.6;
  transition: opacity .3s ease;
  &:hover {
    opacity: 1;
  }
  > svg {
    display: block;
    width: 18px;
    height: auto;
  }
`

export default TeamSection;