import AWS from 'aws-sdk';
import axios from 'axios';
import Web3 from 'web3/dist/web3.min.js'
// import { contract_address } from '../contracts';

const contract_address = '0xEf5C87717aA196D215b4F872FaC3dA77fC5A10E1';

const awsConfig = {
  "region": process.env['REACT_APP_AWS_REGION'],
  "endpoint": process.env['REACT_APP_AWS_ENDPOINT'],
  "accessKeyId": process.env['REACT_APP_AWS_ACCESS_KEY_ID'],
  "secretAccessKey": process.env['REACT_APP_AWS_SECRET_ACCESS_KEY'],
};


const web3 = new Web3(Web3.givenProvider);
const contractABI = require('./getCollectionAbi.json');

export const getCollection = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;

  const nft_ids = await contract.methods['tokensOfOwner'](currentWalletAddress).call()
  // const nft_ids = [2,37,6,7]
  console.log({ nft_ids })
  
  try {
    const result = await getNFTAssets(nft_ids);
    return result;
  }
  catch (err) {
    return [];
  }
  



};

function getNFTAssets(ids) {
  const result = [];
  return new Promise((resolve, reject) => {
    let count = 0;

    function get(id) {
      getAsset(Number(id)).then(res => {
        if (res) {
          const meta = {
            name: `Cozies #${res.tokenID}`,
            description: '',
            attributes: [],
          }
          if (res.Aura) { meta.attributes.push({ trait_type: 'Aura', value: res['Aura'] }) }
          if (res.Background) { meta.attributes.push({ trait_type: 'Background', value: res['Background'] }) }
          if (res.Backpack) { meta.attributes.push({ trait_type: 'Backpack', value: res['Backpack'] }) }
          if (res.Fren) { meta.attributes.push({ trait_type: 'Fren', value: res['Fren'] }) }
          if (res.Hair) { meta.attributes.push({ trait_type: 'Hair', value: res['Hair'] }) }
          if (res.Hand) { meta.attributes.push({ trait_type: 'Hand', value: res['Hand'] }) }
          if (res.Head) { meta.attributes.push({ trait_type: 'Head', value: res['Head'] }) }
          if (res.Journey) { meta.attributes.push({ trait_type: 'Journey', value: res['Journey'] }) }
          if (res.Legs) { meta.attributes.push({ trait_type: 'Legs', value: res['Legs'] }) }
          if (res.Scars) { meta.attributes.push({ trait_type: 'Scars', value: res['Scars'] }) }
          if (res.Shoe) { meta.attributes.push({ trait_type: 'Shoe', value: res['Shoe'] }) }
          if (res.Top) { meta.attributes.push({ trait_type: 'Top', value: res['Top'] }) }
          
          
          meta.image = `https://cozies-nft-image.s3.us-west-2.amazonaws.com/${res.image.replace('ipfs://', '')}.jpg`;
          result.push({ id: res.tokenID, ...meta })
          console.log('opensea failed',{ id: res.tokenID, ...meta })
          if (result.length === ids.length) {
            resolve(result);
          }
          else {
            count +=1;
            get(ids[count]);
          }
          // if (result.length === ids.length) {
          //   resolve(result);
          // }
        }
        // else {
        //   reject()
        // }

      })

    }

    get(ids[count]);
    
    
    
    // ids.forEach(id => {
    //   getAsset(Number(id)).then(res => {
    //     if (res) {
    //       const meta = {
    //         name: `Cozies #${id}`,
    //         description: '',
    //         attributes: [],
    //         // attributes: Object.keys(res).map(key => ({ trait_type: key, value: res[key] }))
    //       }
    //       if (res.Aura) { meta.attributes.push({ trait_type: 'Aura', value: res['Aura'] }) }
    //       if (res.Background) { meta.attributes.push({ trait_type: 'Background', value: res['Background'] }) }
    //       if (res.Backpack) { meta.attributes.push({ trait_type: 'Backpack', value: res['Backpack'] }) }
    //       if (res.Fren) { meta.attributes.push({ trait_type: 'Fren', value: res['Fren'] }) }
    //       if (res.Hair) { meta.attributes.push({ trait_type: 'Hair', value: res['Hair'] }) }
    //       if (res.Hand) { meta.attributes.push({ trait_type: 'Hand', value: res['Hand'] }) }
    //       if (res.Head) { meta.attributes.push({ trait_type: 'Head', value: res['Head'] }) }
    //       if (res.Journey) { meta.attributes.push({ trait_type: 'Journey', value: res['Journey'] }) }
    //       if (res.Legs) { meta.attributes.push({ trait_type: 'Legs', value: res['Legs'] }) }
    //       if (res.Scars) { meta.attributes.push({ trait_type: 'Scars', value: res['Scars'] }) }
    //       if (res.Shoe) { meta.attributes.push({ trait_type: 'Shoe', value: res['Shoe'] }) }
    //       if (res.Top) { meta.attributes.push({ trait_type: 'Top', value: res['Top'] }) }
          
          
    //       getImage(id).then(imgUrl => {
    //         meta.image = imgUrl;
    //         // meta.image = `https://ipfs.io/ipfs/${res.image.replace('ipfs://', '')}`;
    //         // meta.image = `https://tgop.s3.ap-southeast-1.amazonaws.com/nft/images/1.jpg`;
            
    //         result.push({ id, ...meta })
    //         if (result.length === ids.length) {
    //           resolve(result);
    //         }
    //       })
          
    //     }
    //     else {
    //       reject()
    //     }

    //   })
    // })
  })
}

function getImage(id) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.opensea.io/api/v1/asset/0xef5c87717aa196d215b4f872fac3da77fc5a10e1/${id}/`, {
      headers: {
        "X-API-KEY":"7475666b2acb4d96850845f480169525"
      }
    })
    .then(res => {
      resolve(res.data.image_url);
    })
    .catch(err => {
      reject();
    })
  })
  
}

// export function getAttribute() {
//   const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io')

//   const openseaSDK = new OpenSeaSDK(provider, {
//     networkName: Network.Main,
//     apiKey: YOUR_API_KEY
//   })
// }


export function getAsset(id) {
  return new Promise((resolve, reject) => {
      AWS.config.update(awsConfig);

      var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName: "cozies-metadata",
        Key: {
          tokenID: id
        }
      };
      // var params = {
      //   TableName: "cozies-metadata",
      //   Key: {
      //     tokenID: id
      //   },
      //   FilterExpression: "tokenID = :a",
      //   ExpressionAttributeValues: { ":a": id }
      // };
      // docClient.scan(params, onScan);
      docClient.get(params).promise().then(res => {
        resolve(res.Item)
      });
    
      function onScan(err, data) {
        if (err) {
          reject(null);
        } else {        
          const result = data.Items[0];
          resolve(result);
  
          // continue scanning if we have more items
          if (typeof data.LastEvaluatedKey != "undefined") {
            reject(null);
          }
        }
      }
  })  
}