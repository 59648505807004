import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import useCountdown from '../../utils/useCountdown';
import { colors } from '../../constants/colors';
import { Timer } from '../../components/Icons';
import { dutch_auction } from '../../contracts';

const DutchAuctionTimer = ({ onTimeStep, ...props}) => {
  const { duration, time_step } = dutch_auction;
  const [ step, setStep ] = useState(null);

  const currentCountdowns = useMemo(() => {
    const startTimeWithGMT = new Date(duration.start).toUTCString();
    const startTime = new Date(startTimeWithGMT).getTime();
    const endTimeWithGMT = new Date(duration.end).toUTCString();
    const endTime = new Date(endTimeWithGMT).getTime();
    const timeStep = time_step * 60 * 1000;
    const countdowns = [];
    let currentCountdown = startTime;

    while (currentCountdown + timeStep < endTime) {
      currentCountdown += timeStep;
      countdowns.push(currentCountdown);
    };

    return countdowns;
  }, [duration, time_step])
  
  useEffect(() => {
    const currentTimeWithGMT = new Date().toUTCString();
    const currentTime = new Date(currentTimeWithGMT).getTime();
    let result = 0;
    currentCountdowns.forEach((item, i) => {
      if (currentTime > item) {
        result = i;
      }
      else {
        return
      }
    })
    setStep(result+1);
  }, [currentCountdowns])

  const { hours, minutes, seconds } = useCountdown({
    datetime: currentCountdowns[step] || null,
    onTimeUp: handleCountGoToNextStep
  });

  function handleCountGoToNextStep() {
    setStep(step+1);
  }

  useEffect(() => {
    onTimeStep(step)
  }, [step])

  function numberTransfer(num) {
    const length = (num + '').length;
    let str = Math.max(num, 0);
    if (length === 1) {
      str = `0${num}`;
    }
    return str;
  }
  
  return (
    <Root {...props}>
      <Timer />
      <TimerWrapper>
        <span>{ hours ? numberTransfer(hours) : '00' }</span>
        <span>:</span>
        <span>{ minutes ? numberTransfer(minutes) : '00' }</span>
        <span>:</span>
        <span>{ seconds ? numberTransfer(seconds)  : '00' }</span>
      </TimerWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  > svg {
    color: ${colors.white};
  }
`

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 24px;
  font-family: 'Economica';
  line-height: 1;
  color: ${colors.white};
  ${respondTo.md} {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    margin-top: 4px;
  }
  p {
    display: inline-block;
  }
  span {
    display: inline-block;
  }
`

export default DutchAuctionTimer;