import { contractChainId, nftPrice } from '../contracts';
import { ethers } from 'ethers';
import { mint_function, contract_address } from '../contracts';
import Web3 from 'web3/dist/web3.min.js'

const web3 = new Web3(Web3.givenProvider);
const contractABI = require('../contracts/contract-abi.json');

function chainMapping(id){
  let name = '';
  let url = '';
  switch (id) {
    case (id === "0x1"):
      name = 'Mainnet';
      url = 'https://etherscan.io/';
      break;
    case (id === "0x3"):
      name = 'Ropsten Test Network';
      url = 'https://ropsten.etherscan.io/';
      break;
    case (id === "0x4"):
      name = 'Rinkeby Test Network';
      url = 'https://rinkeby.etherscan.io/';
      break;
    case (id === "0x5"):
      name = 'Goerli Test Network';
      url = 'https://goerli.etherscan.io/';
      break;
    case (id === "0x2a"):
      name = 'Kovan Test Network';
      url = 'https://kovan.etherscan.io/';
    default:
      name = '';
      url = '';
  }
  return { name, url };
}

function getTransactionParams(func_name, amount, merkleTreeValue) {
  const currentContract = new web3.eth.Contract(contractABI, contract_address);

  const data = func_name === 'mint' ? 
  currentContract.methods[func_name](parseInt(amount)).encodeABI()
  :
  currentContract.methods[func_name](parseInt(amount), merkleTreeValue).encodeABI()


  return {
    to: contract_address,
    from: window.ethereum?.selectedAddress,
    value: ethers.utils.hexlify(
        ethers.utils.parseEther((parseInt(amount) * nftPrice).toString())
      ).slice(2).replace(/^0+/, ''),
  
    // TODO: NFT mint method here
    data: data,
    chainId: contractChainId,
  }
}

export const mintNFT = async (step, amount, merkleTreeValue) => {

  let mint_func;

  if (step === 'golden') {
    mint_func = 'goldenlistMint';
  }
  else if (step === 'whitelist') {
    mint_func = 'whitelistMint';
  }
  else {
    mint_func = 'mint';
  }

  let status = '';
  let message = '';

  if (contract_address !== contractChainId) {
    const { name } = chainMapping(contractChainId);
    status = 'failed';
    message = `You should be using ${name}.`;
  }

  const transactionParams = getTransactionParams(mint_func, amount, merkleTreeValue);

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParams],
    });

    const { url } = chainMapping(contract_address);
    status = 'succeed';
    message = `checkout out on ${url}tx/${txHash} later.`
    
  }
  catch (err) {
    status = 'failed';
    message = `something went wrong: ${err.message}`;
  }

  return { status, message };
};


export const mintNFTWithDutchAuction = async (amount) => {
  const currentContract = new web3.eth.Contract(contractABI, contract_address);
  const contactNftCost = await currentContract.methods['cost']().call();
  const dutchAuctionNftPrice = web3.utils.fromWei(contactNftCost, 'ether');

  let status = '';
  let message = '';

  if (contract_address !== contractChainId) {
    const { name } = chainMapping(contractChainId);
    status = 'failed';
    message = `You should be using ${name}.`;
  }

  const transactionParams = {
    to: contract_address,
    from: window.ethereum?.selectedAddress,
    value: ethers.utils.hexlify(
        ethers.utils.parseEther((parseInt(amount) * dutchAuctionNftPrice).toString())
      ).slice(2).replace(/^0+/, ''),
  
    data: currentContract.methods['mint'](parseInt(amount)).encodeABI(),
    chainId: contractChainId,
  }
  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParams],
    });

    const { url } = chainMapping(contract_address);
    status = 'succeed';
    message = `checkout out on ${url}tx/${txHash} later.`
    
  }
  catch (err) {
    status = 'failed';
    message = `something went wrong: ${err.message}`;
  }

  return { status, message };
};


export const mintNFTNew = async (whiteListIdentify, amount, price) => {

  let status = '';
  let message = '';

  if (contract_address !== contractChainId) {
    const { name } = chainMapping(contractChainId);
    status = 'failed';
    message = `You should be using ${name}.`;
  }

  const currentContract = new web3.eth.Contract(contractABI, contract_address);
  
  const data = whiteListIdentify ? 
    currentContract.methods['mintWhitelistTicket'](amount, whiteListIdentify.maxQuantity, whiteListIdentify.signedSinature).encodeABI()
    :
    currentContract.methods['mintPublicTicket']().encodeABI()

  const mintPrice = whiteListIdentify ? 0 : price;

  const transactionParams = {
    to: contract_address,
    from: window.ethereum?.selectedAddress,
    value: ethers.utils.hexlify(
        ethers.utils.parseEther((parseInt(amount) * mintPrice).toString())
      ).slice(2).replace(/^0+/, ''),
  
    // TODO: NFT mint method here
    data: data,
    chainId: contractChainId,
  }

  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParams],
    });

    const { url } = chainMapping(contract_address);
    status = 'succeed';
    message = `checkout out on ${url}tx/${txHash} later.`
    
  }
  catch (err) {
    status = 'failed';
    message = `something went wrong: ${err.message}`;
  }

  return { status, message };
};
