import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { BottomBurger } from '../../components/Icons';
import { lockWindow } from '../../utils/methods';
import useConnectWallet from '../../utils/useConnectWallet';
import { Link } from 'react-router-dom';
import { exchangeNft, getTickets } from './exchangeTicket';
import { detectExchangeEnable } from '../NewMintPage/MintableStatus';
import ModalBox from '../../components/ModalBox';

const ExchangePage = () => {
  const wording = _w('exchange');
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isEnable, setIsEnable ] = useState(false);
  const [ modalStatus, setModalStatus ] = useState(null);
  const [ ticket, setTicket ] = useState(null);
  const [ select, setSelect ] = useState([]);
  // const [ isBottomOpen, setIsBottomOpen ] = useState(true);
  const { status: walletStatus, onConnect } = useConnectWallet();

  useEffect(() => {
    handleDetectExchangeEnable();
  }, [])

  async function handleDetectExchangeEnable() {
    const isExchangeEnable = await detectExchangeEnable();
    setIsEnable(isExchangeEnable);
  }
  
  useEffect(() => {
    handleEntryStatus();
  }, [walletStatus])

  async function handleEntryStatus() {
    if (walletStatus === 'disconnected') {
      onConnect();
    }
    else if (walletStatus === 'connected') {
      const result = await getTickets();
      // const ticketData = [1,2,3];
      const ticketData = result.map(item => Number(item));

      setTicket(ticketData);
      setSelect([ticketData[0]] || []);
    }
  }

  function handleClickItem(e) {
    const currentSelect = [...select];
    const id = Number(e.currentTarget.dataset.id);
    const isFindIndex = currentSelect.findIndex(item => item === id);
    if (isFindIndex !== -1) {
      currentSelect.splice(isFindIndex, 1);
    }
    else {
      currentSelect.push(id);
    }
    setSelect(currentSelect);
  }

  function handleClickToggleButton() {
    // const currentOpen = !isBottomOpen;
    // setIsBottomOpen(currentOpen);
    // lockWindow(currentOpen);
  }

  async function handleClickExchangeButton() {
    const result = await exchangeNft(select);
    setModalStatus(result.status);
    setIsModalOpen(true);
  }

  async function handleClickModalClose() {
    const result = await getTickets();
    const ticketData = result.map(item => Number(item));
    setTicket(ticketData);

    // setIsModalOpen(false);
    window.location.reload();
  }

  return (
    <Root>
      <ModalBox open={isModalOpen} onClose={handleClickModalClose}>
        <ModalContent>
          { modalStatus === 'succeed' &&
            <img src="/images/exchange-sucess.gif" alt="" />
          }
          <p>{wording[modalStatus]}</p>
          <button onClick={handleClickModalClose}>OK</button>
        </ModalContent>
      </ModalBox>
      { (!isEnable || ticket?.length === 0) ?
        <>
          <Background>
            <img className="line" src="/images/background-3.svg" alt="" />
            <img className="background" src="/images/manifesto-bg.png" alt="" />
          </Background>
          <Main>
            { !isEnable &&
              <div className="title">{ wording.not_yet }</div>
            }
            { select?.length === 0 &&
              <div className="title">{ wording.not_found }</div>
            }
            <Link to="/mint">{ wording.get_tickets }</Link>
          </Main>
        </>
        :
        <>
          {/* <ListSection open={isBottomOpen}> */}
          <ListSection>
            <button className="toggle-button" onClick={handleClickToggleButton}>
              <BottomBurger />
            </button>
            <div className="container">
              <Head>
                <Heading>
                  <span>{ wording.heading }</span>
                </Heading>
                <Description>{ wording.description }</Description>
              </Head>
              {/* <ListWrapper open={isBottomOpen}> */}
              <ListWrapper>
                <div className="title">{ wording.heading }</div>
                <List>
                  { ticket?.map((item, i) =>
                    <li key={i}>
                      <Item
                        data-id={item}
                        active={select.includes(item)}
                        onClick={handleClickItem}>
                        <div className="image">
                          <img className="main" src="/images/exchange-item.png" alt="" />
                          { select.includes(item) && <img className="exchange" src="/images/exchange-check.svg" /> }
                        </div>
                        <p>#{item}</p>
                      </Item>
                    </li>
                  ) }
                </List>
              </ListWrapper>
            </div>
          </ListSection>
          <BottomBar>
          {/* <BottomBar show={select?.length !== 0}> */}
            <Button onClick={handleClickExchangeButton} disabled={select.length === 0}>{ wording.exchange }</Button>
            <Button as={Link} to="/collection">{ wording.check_my_cozies }</Button>
          </BottomBar>
        </>
      }
    </Root>
  )
}

const Root = styled.div`
  width: 100vw;
  min-height: calc(100vh - 112px);
  background-color: ${colors.brightBlue};

  overflow: hidden;
`

const ListSection = styled.div`
  padding: 72px 0;
  padding-bottom: 120px;
  border-radius: 12px 12px 0 0;
  background-color: ${colors.brightBlue};
  min-height: 100vh;
  > .container {
    margin: 0 auto;
    padding: 0 20px;
    width: 1100px;
    max-width: 100%;
    box-sizing: border-box;
  }
  > .toggle-button {
    display: none;
    border: 0;
    padding: 0;
    background: transparent;
    color: ${colors.lightBlue};
    ${respondTo.md} {
      display: block;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
const Head = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 72px;
  margin-bottom: 60px;
  ${respondTo.md} {
    display: none;
  }
`
const Heading = styled.div`
  font-size: 48px;
  font-family: 'Economica';
  line-height: 1;
  color: ${colors.blue};
`

const Description = styled.div`
  max-width: 60%;
  font-size: 18px;
  font-family: 'Economica';
  line-height: 1;
  white-space: break-spaces;
  color: ${colors.blue};
`

const ListWrapper = styled.div`
  > .title {
    margin-bottom: 12px;
    font-size: 48px;
    font-family: 'Economica';
    line-height: 1;
    text-align: center;
    color: ${colors.blue};
    display: none;
    ${respondTo.md} {
      display: block;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${respondTo.md} {
    margin: 0 -3px;
  }
  > li {
    margin-bottom: 32px;
    padding: 0 15px;
    width: 25%;
    box-sizing: border-box;
    ${respondTo.md} {
      margin-bottom: 8px;
      padding: 0 3px;
      width: 33.33%;
    }
  }
`
const Item = styled.div`
  cursor: pointer;
  .image {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    transition: all .3s ease;
    ${({ active }) => active && css`
      box-shadow: 0px 0px 0px 3px ${colors.blue},
                  0px 0px 0px 8px ${colors.brightBlue},
                  0px 0px 0px 9px ${colors.blue};
    `}
    ${respondTo.md} {
      ${({ active }) => active && css`
        box-shadow: 0px 0px 0px 3px ${colors.blue};
      `}
    }
    .main {
      display: block;
      width: 100%;
      height: auto;
    }
    .exchange {
      display: block;
      position: absolute;
      right: 12px;
      bottom: 12px;
    }
  }
  p {
    margin-top: 12px;
    font-family: 'Economica';
    font-size: 18px;
    color: ${colors.blue};
    line-height: 1;
    text-align: center;
    ${respondTo.md} {
      margin-top: 6px;
    }
  }
`

const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding: 72px 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #EAF5F8;
  z-index: 2;
  /* transform: translateY(100%); */
  /* transition: transform .3s ease; */
  /* ${({ show }) => show && css` */
    /* transform: translateY(0); */
  /* `} */
`
const Button = styled.button`
  border: 1px solid ${colors.blue};
  border-radius: 24px;
  padding: 8px;
  width: 190px;
  font-family: 'Economica';
  font-size: 24px;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  color: ${colors.blue};
  background: ${colors.white};
  & + a, & + button {
    margin-top: 12px;
  }
  ${({ disabled }) => disabled && css`
    opacity: 0.6;
    /* background: rgba(105, 167, 202, .6);
    color: ${colors.white}; */
    cursor: not-allowed;
  `}
`


const Background = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.brightBlue};
  z-index: 0;
  ${respondTo.md} {
    width: auto;
    height: auto;
  }
  > .line {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    height: auto;
    transform: translateY(-50%);
    ${respondTo.md} {
      display: none;
    }
  }
  > .background {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    ${respondTo.md} {
      object-fit: initial;
      width: 100%;
      height: auto;
    }
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  > .title {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 1;
    font-family: 'Economica';
    color: ${colors.blue};
    text-align: center;
    ${respondTo.md} {
      font-size: 24px;
    }
  }
  > .content {
    margin: 0 auto;
    width: 600px;
    max-width: 90%;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: ${colors.darkBlue};
    font-style: italic;
    text-align: center;
    white-space: break-spaces;
    ${respondTo.md} {
      text-align: justify;
    }
  }
  > .quote {
    margin-top: 12px;
    margin-bottom: 48px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${colors.darkBlue};
  }
  > a {
    display: inline-block;
    border: 0;
    border-radius: 5px;
    margin-bottom: 60px;
    padding: 4px 12px;
    line-height: 1;
    font-family: 'Economica';
    color: ${colors.white};
    text-align: center;
    background-color: ${colors.opacityBlue};
  }
`

const ModalContent = styled.div`
  padding: 24px;
  text-align: center;
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 100%;
    width: 120px;
  }
  > p {
    font-size: 18px;
    font-family: 'Economica';
    line-height: 1;
    white-space: break-spaces;
    color: ${colors.blue};
  }
  > button {
    display: inline-block;
    border: 0;
    border-radius: 5px;
    margin-top: 36px;
    padding: 4px 12px;
    line-height: 1;
    font-family: 'Economica';
    color: ${colors.white};
    text-align: center;
    background-color: ${colors.opacityBlue};
  }
`

export default ExchangePage;