import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Wallet from '../Wallet';
import Player from '../Player';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const FixedBlock = () => {
  const wording = _w('setting');
  const mathHomePage = useRouteMatch('/');
  const { isExact } = mathHomePage;
  
  return (
    <Root hide={!isExact}>
      <div className="left">
        <Player data={wording.audio} />
      </div>
      <div className="right">
        <Wallet msg_gold={wording.ticket.msg_gold} msg_whitelist={wording.ticket.msg_whitelist} />
      </div>
    </Root>
  )
}


const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  pointer-events: none;
  ${({ hide }) => hide && css`
    opacity: 0;
    visibility: hidden;
  `}
  > .left {
    position: absolute;
    left: 40px;
    bottom: 60px;
    pointer-events: auto;
    ${respondTo.md} {
      display: none;
    }
  }
  > .right {
    position: absolute;
    right: 40px;
    bottom: 60px;
    pointer-events: auto;
    ${respondTo.md} {
      display: none;
    }
  }
`

export default FixedBlock;