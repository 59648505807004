import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { scrollTo } from '../../utils/scrollTo';
import { _w } from '../../utils/wordingSystem';

const BottomSection = () => {
  const wording = _w('journey.bottom');

  function handleClickBackButton() {
    scrollTo(1, 0);
  }
  
  return (
    <Root>
      <button onClick={handleClickBackButton} className="back-to-top">{ wording.back_to_top }</button>
      <Link to="/manifesto" className="go-to">{ wording.view_the_manifesto }</Link>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  margin-top: 80px;
  background-image: linear-gradient(0deg, ${colors.brightBlue} 0%, ${colors.brightBlue} 80%, hsla(0, 0%, 100%, 0) 100%);
  display: none;
  z-index: 1;
  ${respondTo.md} {
    display: block;
  }
  button {
    display: none;
    opacity: 0.6;
    ${respondTo.md} {
      display: block;
    }
  }
  .back-to-top {
    font-family: "Economica";
    border: 1px solid ${colors.blue};
    border-radius: 15px;
    margin: 0 auto;
    padding: 15px;
    width: calc(100% - 48px);
    font-size: 36px;
    color: ${colors.blue};
    background-color: transparent;
  }
  .go-to {
    display: block;
    position: relative;
    border: 1px solid ${colors.blue};
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 15px;
    width: calc(100% - 48px);
    font-family: "Economica";
    font-size: 36px;
    text-align: center;
    color: ${colors.white};
    background-color: ${colors.blue};
    box-sizing: border-box;
  }
`

export default BottomSection;