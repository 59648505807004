import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { useWordingLoader } from './utils/wordingSystem';

import appStore from './store/app';
import walletStatus from './store/walletStatus';

import GlobalStyle from './components/GlobalStyle';
import AutoScrollHelper from './components/AutoScrollHelper';
import ScrollToTopHelper from './components/ScrollToTopHelper';
import HomePage from './pages/HomePage';
import TrainPage from './pages/TrainPage';
import JourneyPage from './pages/JourneyPage';
import ManifestoPage from './pages/ManifestoPage';
import MintPage from './pages/MintPage';
import DutchAuctionMintPage from './pages/DutchAuctionMintPage';
import NewMintPage from './pages/NewMintPage';
import CollectionPage from './pages/CollectionPage';
import ExchangePage from './pages/ExchangePage';
import TermsPage from './pages/TermsPage';
import FixedBlock from './components/FixedBlock';
import Header from './components/Header';
import Footer from './components/Footer';

const reducer = combineReducers({
  app: appStore.reducer,
  walletStatus: walletStatus.reducer,
});

const store = createStore(reducer);

const App = ({ wording, Router = BrowserRouter }) => {
  const wordingLoaded = useWordingLoader(wording ?? '/wordings/main.json?t=20221111');

  return (
    <Provider store={store}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link href="https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Inter:wght@400;500;700&display=swap" rel="stylesheet"></link>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" rel="stylesheet"></link>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" rel="stylesheet"></link>
      </Helmet>
      <GlobalStyle/>
      <Router>
        { !wordingLoaded &&
          <Cover></Cover>
        }
        { wordingLoaded &&
          <>
            <ScrollToTopHelper />
            <AutoScrollHelper />
            <Header />
            <FixedBlock />
            <Switch>
              <Route path="/" exact={true} component={HomePage} />
              <Route path="/train" exact={true} component={TrainPage} />
              <Route path="/the-journey" exact={true} component={JourneyPage} />
              <Route path="/manifesto" exact={true} component={ManifestoPage} />
              <Route path="/mint" exact={true} component={NewMintPage} />
              {/* <Route path="/mint" exact={true} component={MintPage} /> */}
              {/* <Route path="/mint" exact={true} component={DutchAuctionMintPage} /> */}
              <Route path="/collection" exact={true} component={CollectionPage} />
              <Route path="/unlockcozies" exact={true} component={ExchangePage} />
              <Route path="/terms" exact={true} component={TermsPage} />
            </Switch>
            <Footer />
          </>
        }
      </Router>
    </Provider>
  );
}

const Cover = styled.div`
`

export default App;
