import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { metamaskAppUrl } from "../contracts";
import walletStatus from '../store/walletStatus';

const useConnectWallet = () => {
  const dispatch = useDispatch();
  const { status, walletAddress, walletDisplay, isNotFound } = useSelector(state => state.walletStatus);

  function simplifyAddress(address) {
    return address.slice(0,6) + '...' + address.slice(-4);
  }

  const handleConnectWalletSuccess = (address) => {
    dispatch(walletStatus.actions.setWalletStatusConnected());
    dispatch(walletStatus.actions.setWalletAddress(address));
    dispatch(walletStatus.actions.setWalletDisplay(simplifyAddress(address)));
    dispatch(walletStatus.actions.setWalletIsFound());
  }

  // const handleClearWalletId = () => {
  //   dispatch(walletStatus.actions.setWalletdStatusDisconnected());
  //   dispatch(walletStatus.actions.setWalletId(null));
  // }



  useEffect(async () => {
    // await fetchWalletAPI();
  }, [])

  // async function fetchWalletAPI() {
  //   // const address = await getWalletAddress();
  //   const address = await getEthereumAddress({ method: "eth_accounts" });

  //   // First get wallet address
  //   if (address === null) {
  //     dispatch(walletStatus.actions.setWalletIsNotFound());
  //   }
  //   if (address && address.length > 0){
  //     handleConnectWalletSuccess(simplifyAddress(address));
  //   }
  //   addWalletListener();
  // }
  
  // function addWalletListener() {
  //   if (window.ethereum) {
  //     window.ethereum.on("accountsChanged", (accounts) => {
  //       if (accounts.length > 0) {
  //         handleConnectWalletSuccess(simplifyAddress(accounts[0]))
  //       } else {
  //         handleClearWalletId();
  //       }
  //     });
  //   } else {
  //     handleClearWalletId();
  //     dispatch(walletStatus.actions.setWalletIsNotFound());
  //   }
  // }

  async function handleConnectWallet () {
    const isMobileDevice = isAndroid || isIOS;
    const isAppNotFound = !window.ethereum || !window.ethereum.isMetaMask;
    if (isMobileDevice && isAppNotFound) {
      window.location = metamaskAppUrl;
    }
    else {
      const address = await getEthereumAddress({ method: "eth_requestAccounts" });
      if (address === null) {
        dispatch(walletStatus.actions.setWalletIsNotFound());
      }
      if (address && address.length > 0){
        handleConnectWalletSuccess(address);
      }
    }
  }

  const getEthereumAddress = async (request) => {
    let address = null;
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request(request);
        address = addressArray[0];
      }
      catch (err) {
        address = '';
        console.log(err);
      }
    } else { 
      address = null;
    }
    return address;
  };

  return { 
    walletAddress,
    walletDisplay,
    status,
    isNotFound,
    onConnect: handleConnectWallet,
  }

  
}

export default useConnectWallet;