import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import HeadingSection from './HeadingSection';
import InfoSection from './InfoSection';
import TeamSection from './TeamSection';
import FAQSection from './FAQSection';
import BottomSection from './BottomSection';
import { respondTo } from '../../utils/responsive';
import MobileBottomBar from '../../components/MobileBottomBar';
import { _w } from '../../utils/wordingSystem';

const TrainPage = () => {
  const wording = _w('train');

  return (
    <Root>
      <Helmet>
        <title>{wording.meta_title}</title>
      </Helmet>
      <HeadingSection />
      <InfoSection />
      <TeamSection />
      <FAQSection />
      <BottomSection />
      <MobileBottomBar />
    </Root>
  )
}

const Root = styled.div`
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`

export default TrainPage;