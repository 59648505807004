import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ShowcaseSection from './ShowcaseSection';
import Carousel from '../../components/Carousel';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { BottomBurger, Twitter } from '../../components/Icons';
import { lockWindow } from '../../utils/methods';
import { getCollection } from '../../utils/getCollection';
import useConnectWallet from '../../utils/useConnectWallet';

const CollectionPage = () => {
  const wording = _w('collection');
  const [ collection, setCollection] = useState(null);
  const [ current, setCurrent ] = useState(0);
  const [ isBottomOpen, setIsBottomOpen ] = useState(false);
  const { status: walletStatus, onConnect } = useConnectWallet();

  useEffect(() => {
    handleEntryStatus();
  }, [walletStatus])

  async function handleEntryStatus() {
    if (walletStatus === 'disconnected') {
      onConnect();
    }
    else if (walletStatus === 'connected') {
      const data = await getCollection();
      setCollection(data);
    }
  }

  function handleClickItem(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrent(index);
  }

  function handleClickToggleButton() {
    const currentOpen = !isBottomOpen;
    setIsBottomOpen(currentOpen);
    lockWindow(currentOpen);
  }

  return (
    <>
    { collection === null ?
      <Empty>LOADING...</Empty>
      :
      <>
        { (collection && collection.length !== 0) ?
          <Root>
            <ShowcaseSection data={collection[current]} />
            <ListSection open={isBottomOpen}>
              <button className="toggle-button" onClick={handleClickToggleButton}>
                <BottomBurger />
              </button>
              <div className="container">
                <Head>
                  <Heading>
                    <span>{ wording.heading }</span>
                    <TwitterButton>
                      <Twitter />
                      <span>{ wording.share_on_twitter }</span>
                    </TwitterButton>
                  </Heading>
                  <Description>{ wording.description }</Description>
                </Head>
                <ListWrapper open={isBottomOpen}>
                  <div className="title">{ wording.heading }</div>
                  <List>
                    { collection.map((item, i) =>
                      item &&
                      <li key={i}>
                        <Item
                          data-index={i}
                          active={current === i}
                          onClick={handleClickItem}>
                          <div className="image">
                            <img src={item.image} alt="" />
                          </div>
                          <p>#{item.id}</p>
                        </Item>
                      </li>
                    ) }
                  </List>
                  <MobileTwitterButtonWrapper>
                    <MobileTwitterButton>
                      <Twitter />
                      <span>{ wording.share_on_twitter }</span>
                    </MobileTwitterButton>
                  </MobileTwitterButtonWrapper>
                </ListWrapper>
                <MobileCarousel open={!isBottomOpen}>
                  <Carousel setting={{
                    slidesToShow: 3,
                    dots: false
                  }}>
                    { collection.map((item, i) =>
                      item &&
                      <div key={i} className="slide-item">
                        <Item
                          data-index={i}
                          active={current === i}
                          onClick={handleClickItem}>
                          <div className="image">
                            <img src={item.image} alt="" />
                          </div>
                          <p>#{item.id}</p>
                        </Item>
                      </div>
                    ) }
                  </Carousel>
                </MobileCarousel>
              </div>
            </ListSection>
          </Root>
          :
          <Empty>{wording.not_found}</Empty>
        }
      </>
    }
    </>
  )
}

const Root = styled.div`
  width: 100vw;
  overflow: hidden;
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 48px;
  color: ${colors.blue};
  overflow: hidden;
`

const ListSection = styled.div`
  padding: 72px 0;
  border-radius: 12px 12px 0 0;
  background-color: ${colors.brightBlue};
  ${respondTo.md} {
    position: fixed;
    top: calc(100vh - 200px);
    left: 0;
    width: 100vw;
    height: 200px;
    padding: 28px 0;
    transition: all .3s ease;
    ${({ open }) => open && css`
      top: 80px;
      height: calc(100vh - 80px);
      overflow-y: auto;
    `}
  }
  > .container {
    margin: 0 auto;
    padding: 0 20px;
    width: 1100px;
    max-width: 100%;
    box-sizing: border-box;
  }
  > .toggle-button {
    display: none;
    border: 0;
    padding: 0;
    background: transparent;
    color: ${colors.lightBlue};
    ${respondTo.md} {
      display: block;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
const Head = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 60px;
  ${respondTo.md} {
    display: none;
  }
`
const Heading = styled.div`
  font-size: 48px;
  font-family: 'Economica';
  line-height: 1;
  color: ${colors.blue};
`

const TwitterButton = styled.a`
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
  border: 1px solid ${colors.blue};
  border-radius: 6px;
  padding: 4px 12px;
  color: ${colors.blue};
  background: ${colors.white};
  ${respondTo.md} {
    display: none;
  }
  span {
    display: inline-block;
    margin-left: 4px;
    font-family: 'Economica';
    font-size: 16px;
  }
`

const MobileTwitterButtonWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
`

const MobileTwitterButton = styled(TwitterButton)`
  ${respondTo.md} {
    display: inline-flex;
  }
`


const Description = styled.div`
  max-width: 60%;
  font-size: 18px;
  font-family: 'Economica';
  line-height: 1;
  white-space: break-spaces;
  color: ${colors.blue};
`

const MobileCarousel = styled.div`
  display: none;
  ${respondTo.md} {
    ${({open}) => open && css`
    display: block;
    `}
  }
  .slide-item {
    padding: 3px;
    box-sizing: border-box;
  }
`

const ListWrapper = styled.div`
  ${respondTo.md} {
    display: none;
    ${({ open }) => open && css`
      display: block;
    `}
  }
  > .title {
    margin-bottom: 12px;
    font-size: 48px;
    font-family: 'Economica';
    line-height: 1;
    text-align: center;
    color: ${colors.blue};
    display: none;
    ${respondTo.md} {
      display: block;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${respondTo.md} {
    margin: 0 -3px;
  }
  > li {
    margin-bottom: 32px;
    padding: 0 15px;
    width: 25%;
    box-sizing: border-box;
    ${respondTo.md} {
      margin-bottom: 8px;
      padding: 0 3px;
      width: 33.33%;
    }
  }
`
const Item = styled.div`
  cursor: pointer;
  min-height: 200px;
  .image {
    border-radius: 6px;
    overflow: hidden;
    transition: all .3s ease;
    ${({ active }) => active && css`
      box-shadow: 0px 0px 0px 3px ${colors.blue},
                  0px 0px 0px 8px ${colors.brightBlue},
                  0px 0px 0px 9px ${colors.blue};
    `}
    ${respondTo.md} {
      ${({ active }) => active && css`
        box-shadow: 0px 0px 0px 3px ${colors.blue};
      `}
    }
    > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  p {
    margin-top: 12px;
    font-family: 'Economica';
    font-size: 18px;
    color: ${colors.blue};
    line-height: 1;
    text-align: center;
    ${respondTo.md} {
      margin-top: 6px;
    }
  }
`


export default CollectionPage;