import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import LinksList from './LinksList';
import SocialList from './SocialList';
// import LinksListMobile from './LinksListMobile';
// import ConnectButton from './ConnectButton';
// import LanguageButton from './LanguageButton';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
import MenuBurgerButton from '../MenuBurgerButton';
import { _w } from '../../utils/wordingSystem';

const Header = () => {
  const wording = _w('header');

  const headerRef = useRef(null);
  const fixederRef = useRef();
  
  const prevPageYOffset = useRef(0);
  const navbarTop = useRef(0);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMenuOpenRef = useRef(false);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);    
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [])

  function handleWindowScroll() {
    if (!isMenuOpenRef.current) {
      const pageYOffset = Math.max(window.pageYOffset, 0);
      const delta = pageYOffset - prevPageYOffset.current;
      navbarTop.current = navbarTop.current - delta;
      navbarTop.current = Math.max(navbarTop.current, -160);

      if (delta < 0) navbarTop.current = 0;
      fixederRef.current.style.setProperty('transition-duration', (delta < 0) ? '0.3s': '0s');
      fixederRef.current.style.setProperty('transform', `translateY(${navbarTop.current}px)`);

      prevPageYOffset.current = pageYOffset;
    }
  }

  function handleClickMenuButton() {
    setIsMenuOpen(!isMenuOpen);
    isMenuOpenRef.current = !isMenuOpen;
  }

  function handleClickLink() {
    setIsMenuOpen(false);
    isMenuOpenRef.current = false;
  }

  return (
    <Root ref={headerRef}>
      <Fixeder ref={fixederRef}>
        <Wrapper>
          <Link className="logo" to="/">
            <img src={wording.logo} alt={wording.logo_alt} />
          </Link>
          <MenuWrapper open={isMenuOpen}>
            <LinksList className="link-list" data={wording.links} onClickLink={handleClickLink} />
            <SocialList className="social-list" data={wording.socials} />
            {/* <img className="bottom" src="/images/header-bottom.png" alt="" /> */}
            <div className="copyright">{ wording.copyright }</div>
          </MenuWrapper>
          <Buttonbar>
            <SocialList data={wording.socials} />
            <MenuBurgerButton open={isMenuOpen} onClick={handleClickMenuButton} />
          </Buttonbar>
        </Wrapper>
      </Fixeder>
    </Root>
  )
}

const Root = styled.header`
`

const Fixeder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px 12px;
  width: 100%;
  height: 70px;
  z-index: 5;
  box-sizing: border-box;
  background-color: #EFF7F9;
  /* background-color: hsla(0, 0%, 100%, .4); */
  /* backdrop-filter: blur(4px); */
  transition: transform .3s ease;
  ${respondTo.md} {
    padding: 0;
    height: 55px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  transition: all .3s ease ${({time}) => time}ms;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0 20px;
  }
  .logo {
    position: relative;
    display: flex;
    align-items: center;
    width: 125px;
    z-index: 3;
    ${respondTo.md} {
      width: 92px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ respondTo.md } {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    padding-top: 20vh;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: hsla(0, 0%, 99%, 1);
    z-index: 2;
    transform: translateX(100%);
    transition: all .3s ease;
    box-sizing: border-box;
    ${({open}) => open && css`transform: translateX(0%);`}
  }
  .social-list {
    display: none;
    margin-top: 36px;
    > li + li {
      margin-left: 32px;
    }
    ${respondTo.md} {
      display: flex;
    }
  }
  .bottom {
    display: none;
    width: 240px;
    margin: 0 auto;
    margin-top: 60px;
    ${respondTo.md} {
      display: block;
    }
  }
  .copyright {
    display: none;
    color: ${colors.lightBlue};
    text-align: center;
    font-size: 12px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 12px;
    ${respondTo.md} {
      display: block;
    }
  }
`
const Buttonbar = styled.div`
  display: flex;
  > *:last-child {
    margin-left: 12px;
    z-index: 3;
  }
`

export default Header;
