import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
import { useIntersectionObserver } from '../../utils/useInterSectionObserver';
import { _w } from '../../utils/wordingSystem';

const IntroSection = () => {
  const wording = _w('homepage.intro');
  const rootRef = useRef(null);
  const [ current, setCurrent ] = useState(0);

  useIntersectionObserver(rootRef, handleIntersection, {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0
  })

  function handleIntersection(e) {
    if (e[0].isIntersecting) {
      window.addEventListener('scroll', handleWatchScroll)
      
    }
    else {
      window.removeEventListener('scroll', handleWatchScroll)
    }
  }

  function handleWatchScroll() {
    const currentPageYOffset = window.pageYOffset;
    const currentOffsetTop = rootRef.current.offsetTop;
    const percent = (currentPageYOffset + window.innerHeight - currentOffsetTop) / window.innerHeight;

    if (percent >= 0 && percent < 1) {
      const fixPercent = percent * 0.8;
      setCurrent(Math.floor(wording.list.length * fixPercent));
    };
  }
  
  return (
    <Root ref={rootRef}>
      <Content>
        { wording.list.map((item, i) =>
          <Item key={i}
            className={current === i ? 'active' : ''}
          >{ item }</Item>
        ) }
      </Content>
    </Root>
  )
}

const Root = styled.div`
  padding: 150px 0;
  text-align: center;
`

const Content = styled.div`
  position: relative;
`

const Item = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  font-family: "Economica";
  font-size: 30px;
  color: ${colors.blue};
  transform: translateX(-50%);
  transition: all .3s ease;
  opacity: 0;
  &.active {
    opacity: 1;
  }
  ${respondTo.md} {
    width: 80vw;
  }
`

export default IntroSection;