const colors = {
  mainColor: "#FFF",
  darkMainColor: "#DDD4C6",
  backgroundColor: "#F4FBFC",
  green: "#828B75",
  darkBlue: "#3D6A91",
  blue: "#69A7CA",
  opacityBlue: "rgba(105, 167, 202, 0.6)",
  lightBlue: "#C2E0EC",
  brightBlue: "#D9EBF1",
  brown: "#AFA092",
  brandYellow: "#D8DE00",
  black: "#000000",
  red: "#FA4238",
  white: "#FFFFFF",
  gray: "#EFEFEF",
  darkGray: "#D0D0D0",
  highlight: "#FFA243"
}

export { colors };