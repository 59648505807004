import { contract_address } from '../../contracts';
import Web3 from 'web3/dist/web3.min.js'

const web3 = new Web3(Web3.givenProvider);
const contractABI = require('../../contracts/contract-abi.json');

export const detectWhiteListEnable = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['whitelistMintEnabled']().call()

  return result;

};

export const detectGoldenListEnable = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['goldenlistMintEnabled']().call()

  return result;

};

export const getMaxMintAmount = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['maxMintAmountPerTx']().call()

  return Number(result);
};

export const getPaused = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['paused']().call()

  return result;
};

export const getMintPrice = async () => {
  // const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  // contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['cost']().call()


  return web3.utils.fromWei(result, 'ether');
};

export const getStockAmount = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['totalSupply']().call()

  return Number(result);
};

export const getAlreadyMintInWhiteList = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['whitelistClaimed'](currentWalletAddress).call()

  return result;
};

export const getAlreadyMintInGoldenList = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['goldenlistClaimed'](currentWalletAddress).call()

  return result;
};