import { contract_address } from '../../contracts';
import Web3 from 'web3/dist/web3.min.js'

const web3 = new Web3(Web3.givenProvider);
const contractABI = require('../../contracts/contract-abi.json');

export const detectWhiteListEnable = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  let startTime = await contract.methods['mintWhitelistStartTime']().call()
  startTime = Number(startTime);
  let endTime = await contract.methods['mintWhitelistEndTime']().call()
  endTime = Number(endTime);

  const blockNumber = await web3.eth.getBlockNumber();
  const blockStatus = await web3.eth.getBlock(blockNumber);
  const currentTime = blockStatus.timestamp

  return Number(currentTime) < Number(endTime) && Number(currentTime) > Number(startTime);
};

export const detectPublicEnable = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  let startTime = await contract.methods['mintPublicStartTime']().call()
  startTime = Number(startTime);
  let endTime = await contract.methods['mintPublicEndTime']().call()
  endTime = Number(endTime);

  const blockNumber = await web3.eth.getBlockNumber();
  const blockStatus = await web3.eth.getBlock(blockNumber);
  const currentTime = blockStatus.timestamp
  
  return Number(currentTime) < Number(endTime) && Number(currentTime) > Number(startTime);
};

export const detectExchangeEnable = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const startTime = await contract.methods['cutTicketStartTime']().call()
  const endTime = await contract.methods['cutTicketEndTime']().call()

  const blockNumber = await web3.eth.getBlockNumber();
  const blockStatus = await web3.eth.getBlock(blockNumber);
  const currentTime = blockStatus.timestamp

  return Number(currentTime) < Number(endTime) && Number(currentTime) > Number(startTime);
};

export const getMaxMintAmount = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['maxMintAmountPerTx']().call()

  return Number(result);
};

export const getPaused = async (address) => {
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = address;
  const result = await contract.methods['paused']().call()

  return result;
};

export const getStockAmount = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['totalSupply']().call()

  return Number(result);
};

export const getAlreadyMintInWhiteList = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['whitelistClaimed'](currentWalletAddress).call()

  return result;
};

export const getAlreadyMintInGoldenList = async () => {
  const currentWalletAddress = window.ethereum.selectedAddress;
  const contract = new web3.eth.Contract(contractABI, contract_address);
  contract.defaultAccount = currentWalletAddress;
  const result = await contract.methods['goldenlistClaimed'](currentWalletAddress).call()

  return result;
};