import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { Headphone } from '../Icons';

const ToggleButton = ({ size, onClick, ...props }) => {
  return (
    <Root {...props}>
      <Button className={ size } onClick={onClick}>
        <svg className="minus" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <rect x="6" y="10" width="10" height="2" fill="#69A7CA" fillOpacity="0.6"/>
        </svg>
        <Headphone className="headphone" />
      </Button>
    </Root>
  )
}

const Root = styled.div`

`

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.blue};
  border-radius: 50%;
  padding: 0;
  width: 22px;
  height: 22px;
  background: white;
  transition: all .3s ease;
  > svg {
    width: 100%;
    height: auto;
    transition: all .3s ease;
    &.headphone {
      position: absolute;
      left: 50%;
      top: 45%;
      color: ${colors.blue};
      width: 11px;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
  &.min {
    transform: scale(1.5);
    > .headphone { opacity: 1 }
    > .minus { opacity: 0 }
  }
  &.max {
    > .headphone { opacity: 0 }
    > .minus { opacity: 1 }
  }
`

export default ToggleButton;