import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const TermsPage = () => {
  const wording = _w('terms');
  
  return (
    <Root>
      <Helmet>
        <title>{ wording.meta_title }</title>
      </Helmet>
      <Container>
        <Heading>{ wording.heading }</Heading>
        { wording.sections.map((section, i) =>
          <Section key={i}>
            <div className="title">{section.title}</div>
            <div className="content" dangerouslySetInnerHTML={{ __html: section.content }} />
          </Section>
        ) }
      </Container>
      <Bottom>
        <img src="/images/terms-bottom.png" alt="" />
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  padding-top: 220px;
  background-color: ${colors.white};
  ${respondTo.md} {
    padding-top: 80px;
    width: 100vw;
    overflow: hidden;
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  width: 648px;
  max-width: 100%;
  box-sizing: border-box;
`


const Heading = styled.div`
  margin-bottom: 90px;
  font-size: 48px;
  font-family: 'Economica';
  line-height: 1;
  color: ${colors.blue};
  ${respondTo.md} {
    margin-bottom: 60px;
  }
`

const Section = styled.div`
  margin-bottom: 60px;
  > .title {
    margin-bottom: 16px;
    font-family: 'Economica';
    font-size: 36px;
    color: ${colors.blue};
    ${respondTo.md} {
      font-size: 24px;
    }
  }
  > .content {
    font-size: 12px;
    line-height: 1.5;
    color: ${colors.darkBlue};
    white-space: break-spaces;
    text-align: justify;
    > a {
      text-decoration: underline;
    }
  }
`

const Bottom = styled.div`
  > img {
    display: block;
    width: 100%;
  }
`

export default TermsPage;