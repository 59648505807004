import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../constants/colors';

const SoundIcon = ({ status, ...props }) => {
  return (
    <Root play={status} {...props}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </Root>
  )
}

const playAnimations = [];
for (let i = 0; i < 5; i++) {
  playAnimations.push(keyframes`
    from { transform: scaleY(${Math.random()/2}); background: ${colors.white};}
    to { transform: scaleY(1); background: ${colors.white};}
  `)
}


const Root = styled.div`
  display: flex;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    width: 2px;
    height: 10px;
    transform-origin: center bottom;
    & + span {
      margin-left: 1px
    }
    ${({play}) => {
      const animations = play ? playAnimations : null;
      return animations?.map((animation, i) => (
        css` &:nth-child(${i+1}) {
          animation: ${animation} ${0.3 + Math.random()*0.3}s linear infinite alternate;
        }`
      ))
    }
  }
`


export default SoundIcon;