import React from 'react';
import styled from 'styled-components';
import HeadingSection from './HeadingSection';
import MapSection from './MapSection';
import MailSection from './MailSection';
import MobileBottomBar from '../../components/MobileBottomBar';
import { respondTo } from '../../utils/responsive';

const JourneyPage = () => {
  return (
    <Root>
      <HeadingSection />
      <MapSection />
      <MailSection />
      <MobileBottomBar />
    </Root>
  )
}

const Root = styled.div`
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`

export default JourneyPage;