import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import 'normalize.css';

import vhfix from "./utils/vhfix";
import App from './App';

window.Buffer = window.Buffer || require("buffer").Buffer;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W8D4T3M'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('app')
);

vhfix();
