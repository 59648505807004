import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';

const Dialog = ({ content, arrow='center', ...props }) => {
  return (
    <Root arrow={arrow} dangerouslySetInnerHTML={{__html: content}} {...props} >
    </Root>
  )
}

const Root = styled.div`
  border-radius: 16px;
  padding: 12px 24px;
  background-color: ${colors.white};
  font-family: "Economica";
  color: ${colors.blue};
  z-index: 1;
  filter: drop-shadow(-0.5px -0.5px 0px ${colors.blue})
          drop-shadow(0.5px -0.5px 0px ${colors.blue})
          drop-shadow(-0.5px 0.5px 0px ${colors.blue})
          drop-shadow(0.5px 0.5px 0px ${colors.blue});
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    ${({ arrow }) => {
      if (arrow === 'center') return  css`left: 50%;`
      if (arrow === 'left') return  css`left: 40px;`
      if (arrow === 'right') return  css`right: 40px;`
    }}
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 8px 0 8px;
    border-color: ${colors.white} transparent transparent transparent;
    transform: translateX(-50%);
  }
  a {
    text-decoration: underline;
  }
`

export default Dialog;