import React from 'react';
import styled from 'styled-components';
import { Title, Text } from '../../components/Typography';
import Carousel from '../../components/Carousel';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const InfoSection = () => {
  const wording = _w('train.info');
  return (
    <Root>
      <Container>
        <Title className="title">{ wording.title }</Title>
        <Content className="content">{ wording.content }</Content>
      </Container>
      <List>
        { wording.list.map((item, i) => 
          <li key={i}>
            <Item>
              <img className="photo" src={item.photo} alt={item.photo_alt} />
              <div className="title">{ item.title }</div>
              <div className="description">{ item.description }</div>
            </Item>
          </li>
        ) }
      </List>
      <CarouselWrapper>
        <Carousel controller={false}>
          { wording.list.map((item, i) => 
            <li key={i}>
              <Item>
                <img className="photo" src={item.photo} alt={item.photo_alt} />
                <div className="title">{ item.title }</div>
                <div className="description">{ item.description }</div>
              </Item>
            </li>
          ) }
        </Carousel>
      </CarouselWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  background-color: ${colors.brightBlue};
  padding: 160px 0;
  ${respondTo.md} {
    padding: 90px 0;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  ${respondTo.md} {
    width: 284px;
  }
  .title {
    margin-bottom: 24px;
  }
`

const Content = styled(Text)`
  white-space: break-spaces;
  text-align: justify;
  color: ${colors.darkBlue};
`

const List = styled.ul`
  display: flex;
  margin: 0 auto;
  margin-top: 95px;
  width: 950px;
  max-width: 100%;
  ${respondTo.md} {
    display: none;
  }
  > li {
    padding: 0 12px;
    width: 33.33%;
    box-sizing: border-box;
  }
`

const Item = styled.div`
  border-radius: 200px 200px 24px 24px ;
  padding-bottom: 30px;
  height: 100%;
  background-color: ${colors.white};
  text-align: center;
  box-sizing: border-box;
  .photo {
    width: 100%;
    height: auto;
  }
  .title {
    font-size: 40px;
    color: ${colors.blue};
    font-family: 'Economica';
  }
  .description {
    padding: 0 12px;
    font-size: 18px;
    color: ${colors.blue};
    font-family: 'Economica';
  }
`

const CarouselWrapper = styled.div`
  list-style: none;
  margin-top: 60px;
  display: none;
  ${respondTo.md} {
    display: block;
  }
  li {
    margin-bottom: 30px;
    padding: 0 24px;
    box-sizing: border-box;
  }
`


export default InfoSection;