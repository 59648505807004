import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Title } from '../../components/Typography';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { scrollTo } from '../../utils/scrollTo';
import { _w } from '../../utils/wordingSystem';

const SocialSection = () => {
  const wording = _w('homepage.social');

  function handleClickBackButton() {
    scrollTo(1, 0);
  }
  
  return (
    <Root>
      <Heading>
        <Background>
          <img src="/images/homepage-social-bg.svg" alt="" />
        </Background>
        <Container>
          <Title>{ wording.title }</Title>
        </Container>
      </Heading>
      <Links>
        <List>
          <a href={wording.twitter.link} target="_blank">
            <img src={wording.twitter.icon} alt={wording.twitter.alt} />
          </a>
          <a href={wording.instagram.link} target="_blank">
            <img src={wording.instagram.icon} alt={wording.instagram.alt} />
          </a>
          <a href={wording.discord.link} target="_blank">
            <img src={wording.discord.icon} alt={wording.discord.alt} />
          </a>
        </List>
      </Links>
      <Bottom>
        <button onClick={handleClickBackButton} className="back-to-top">{ wording.back_to_top }</button>
        <Link to="/train" className="go-to">{ wording.view_the_train }</Link>
        <img src={wording.bottom} alt={wording.bottom_alt} />
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  background-color: ${colors.brightBlue};
  &:before {
    content: "";
    position: absolute;
    bottom: 99%;
    left: 0;
    width: 100%;
    height: 20vh;
    background-image: linear-gradient(0deg, ${colors.brightBlue} 0%, hsla(0, 0%, 100%, 0) 80%, hsla(0, 0%, 100%, 0) 100%);
  }
`

const Heading = styled.div`
  position: relative;
  padding: 120px 0;
  ${respondTo.md} {
    padding-top: 80px;
    padding-bottom: 0;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  ${respondTo.md} {
    top: 10%;
  }
  > img {
    width: 100%;
    height: auto;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
`

const Links = styled.div`
  margin-top: 120px;
  ${respondTo.md} {
    margin-top: 0;
  }
`
const List = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 650px;
  max-width: 100%;
  z-index: 1;
  ${respondTo.md} {
    flex-direction: column;
    align-items: center;
  }
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transition: box-shadow .3s ease;
    box-shadow: 0px 0px 0px 24px ${colors.brightBlue},
                0px 0px 0px 26px ${colors.white},
                0px 0px 0px 48px ${colors.brightBlue},
                0px 0px 0px 49px ${colors.white};
    &:hover {
      box-shadow: 0px 0px 0px 45px ${colors.brightBlue},
                  0px 0px 0px 47px ${colors.white},
                  0px 0px 0px 53px ${colors.brightBlue},
                  0px 0px 0px 54px ${colors.white};
    }
    ${respondTo.md} {
      margin-top: 120px;
    }
    > img {
      max-width: 100%;
      height: auto;
    }
    &:nth-child(odd) {
      transform: translateY(50%);
      ${respondTo.md} {
        transform: none;
      }
    }
  }
`

const Bottom = styled.div`
  position: relative;
  margin-top: 60px;
  ${respondTo.md} {
    margin-top: 120px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 20vh;
    background: linear-gradient(180deg, rgba(105, 167, 202, 0) 0%, rgba(105, 167, 202, 0.4) 100%);
    z-index: 0;
  }
  img {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 650px;
    max-width: 100%;
    z-index: 0;
  }
  .back-to-top {
    font-family: "Economica";
    border: 1px solid ${colors.blue};
    border-radius: 15px;
    margin: 0 auto;
    padding: 15px;
    width: calc(100% - 48px);
    font-size: 36px;
    color: ${colors.blue};
    background-color: transparent;

    display: none;
    ${respondTo.md} {
      display: block;
    }
  }
  .go-to {
    position: relative;
    border: 1px solid ${colors.blue};
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 15px;
    width: calc(100% - 48px);
    font-family: "Economica";
    font-size: 36px;
    color: ${colors.white};
    background-color: ${colors.blue};
    text-align: center;
    box-sizing: border-box;
    
    display: none;
    ${respondTo.md} {
      display: block;
    }
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 99%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 12px 0 12px;
      border-color: ${colors.blue} transparent transparent transparent;
      transform: translateX(-50%);
    }
  }
`

export default SocialSection;