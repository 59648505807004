import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../utils/responsive';

const Map = () => {
  const [ cardsDisplay, setCardsDisplay ] = useState([false, false, false, false])
  
  function handleClickArea(e) {
    const index = Number(e.currentTarget.dataset.map);
    const currentCardsDisplay = [...cardsDisplay];
    currentCardsDisplay[index] = !currentCardsDisplay[index];

    setCardsDisplay(currentCardsDisplay);
  }
  
  return (
    <Root>
      <div className="mobile">
        <Cover>
          <svg width="284" height="1753" viewBox="0 0 284 1753" fill="none">
            <path onClick={handleClickArea} data-map="0" d="M23,300 C23,293.37259 28.37258,288 35,288 L255,288 C261.627,288 267,293.37258 267,300 L267,576 C267,582.627 261.627,588 255,588 L35,588 C28.37258,588 23,582.627 23,576 L23,300 Z" fill="rgba(0,0,0,0%)"></path>
            <path onClick={handleClickArea} data-map="1" d="M24,640 C24,633.373 29.37258,628 36,628 L256,628 C262.627,628 268,633.373 268,640 L268,916 C268,922.627 262.627,928 256,928 L36,928 C29.37258,928 24,922.627 24,916 L24,640 Z" fill="rgba(0,0,0,0%)"></path>
            <path onClick={handleClickArea} data-map="2" d="M23,980 C23,973.373 28.37258,968 35,968 L255,968 C261.627,968 267,973.373 267,980 L267,1256 C267,1262.627 261.627,1268 255,1268 L35,1268 C28.37258,1268 23,1262.627 23,1256 L23,980 Z" fill="rgba(0,0,0,0%)"></path>
            <path onClick={handleClickArea} data-map="3" d="M23,1370 C23,1363.37 28.37258,1358 35,1358 L255,1358 C261.627,1358 267,1363.37 267,1370 L267,1646 C267,1652.63 261.627,1658 255,1658 L35,1658 C28.37258,1658 23,1652.63 23,1646 L23,1370 Z" fill="rgba(0,0,0,0%)"></path>
          </svg>
          <div className="cards">
            <Card active={cardsDisplay[0]} src="/svg/map-card-1-m.svg" alt="" />
            <Card active={cardsDisplay[1]} src="/svg/map-card-2-m.svg" alt="" />
            <Card active={cardsDisplay[2]} src="/svg/map-card-3-m.svg" alt="" />
            <Card active={cardsDisplay[3]} src="/svg/map-card-4-m.svg" alt="" />
          </div>
        </Cover>
        <img src="/svg/map-m.svg" alt="" />
      </div>
      <div className="desktop">
        <Cover>
          <svg width="1040" height="957" viewBox="0 0 1040 957" fill="none">
            <rect onClick={handleClickArea} data-map="0" className="board" width="244" height="300" rx="12" fill="rgba(0,0,0,0%)"/>
            <rect onClick={handleClickArea} data-map="1" className="board" x="796" width="244" height="300" rx="12" fill="rgba(0,0,0,0%)"/>
            <rect onClick={handleClickArea} data-map="2" className="board" y="368" width="244" height="300" rx="12" fill="rgba(0,0,0,0%)"/>
            <rect onClick={handleClickArea} data-map="3" className="board" x="796" y="368" width="244" height="300" rx="12" fill="rgba(0,0,0,0%)"/>
          </svg>
          <div className="cards">
            <Card active={cardsDisplay[0]} src="/svg/map-card-1.svg" alt="" />
            <Card active={cardsDisplay[1]} src="/svg/map-card-2.svg" alt="" />
            <Card active={cardsDisplay[2]} src="/svg/map-card-3.svg" alt="" />
            <Card active={cardsDisplay[3]} src="/svg/map-card-4.svg" alt="" />
          </div>
        </Cover>
        <img src="/svg/map.svg" alt="" />
      </div>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
  .desktop {
    display: block;
    ${respondTo.md} {
      display: none;
    }
  }
  .mobile {
    display: none;
    ${respondTo.md} {
      display: block;
    }
  }
`

const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  > svg {
    display: block;
    width: 100%;
    height: auto;
  }
  .board {
    cursor: pointer;
  }
  .cards {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    pointer-events: none;
    > img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`

const Card = styled.img`
  opacity: 0;
  width: 100%;
  height: auto;
  transition: opacity .3s ease;
  ${({ active }) => active && css`
    opacity: 1;
  `}
`


export default Map;