import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { total } from '../../contracts';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { getStockAmount } from '../MintPage/MintableStatus';

const HeadingSection = () => {
  const wording = _w('homepage.heading');
  const [ isSoldOut, setIsSoldOut ] = useState(false);
  useEffect(async() => {
    const stockAmount = await getStockAmount();
    if (total === stockAmount) {
      setIsSoldOut(true);
    }
  }, [])
  return (
    <Root>
      <Heading active={isSoldOut}>
        <img src={wording.banner} alt={wording.banner_alt} />
        <img className="cover" src={wording.banner_sold_out} alt={wording.banner_alt} />
      </Heading>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`
const Heading = styled.div`
  position: relative;
  box-sizing: border-box;
  ${respondTo.md} {
    height: 100vh;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    ${({ active }) => active && css`
      opacity: 1;
      pointer-events: auto;
    `}
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15vh;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .5) 0%, hsla(0, 0%, 100%, 0) 80%, hsla(0, 0%, 100%, 0) 100%);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15vh;
    background-image: linear-gradient(0deg, hsla(0, 0%, 100%, .75) 0%, hsla(0, 0%, 100%, 0) 80%, hsla(0, 0%, 100%, 0) 100%);
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    ${respondTo.md} {
      object-fit: cover;
      height: 100%;
    }
  }
`


export default HeadingSection;