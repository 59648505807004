import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const during = 300;

const Monitor = ({ data, mintAmout, mintMax, mintPrice, clickNumber }) => {
  const timerRef = useRef(null);
  const [ monitorData, setMonitorData ] = useState(data);
  const [ Transition, setTransition ] = useState('end');

  
  useEffect( async () => {
    if (data !== monitorData) {
      clearTimeout(timerRef.current);
      setTransition('in');
      await setTimer(() => {
        setTransition('out');
        setMonitorData(data);
      }, during);
      await setTimer(() => {
        setTransition('end');
      }, during);
    }
  }, [data])

  function setTimer(func, time) {
    return new Promise(resolve => {
      timerRef.current = setTimeout(() => {
        func();
        resolve();
      }, time)
    })
  }

  function renderMintSelector() {
    const selectors = [];
    for (let i = 1; i <= mintMax; i++) {
      selectors.push(
        <button
          key={'monitor_selector_' + i}
          className={mintAmout === i ? 'active' : ''}
          data-number={i}
          onClick={clickNumber}>{i}</button>
      );
    }
    return selectors;
  }

  function applyVarious({source, replace}) {
    let result = source;
    Object.keys(replace).forEach(key => {
      const formatterValue = `<span>${replace[key]}</span>`
      result = result.replace(key, formatterValue);
    });
    return result;
  }
  
  const type = monitorData?.type || ''

  return (
    <Root step={Transition}>
      { type === 'heading' &&
        <Heading>{ monitorData?.content }</Heading>
      }
      { (type === 'text' || type === 'mint') &&
        <Text dangerouslySetInnerHTML={{__html: monitorData?.content}} />
      }
      { type === 'img' &&
        <Img src={monitorData?.content} />
      }
      { type === 'status' &&
        <>
          <Title>{ monitorData?.title }</Title>
          <Content>{ monitorData?.content }</Content>
        </>
      }
      { type === 'various' &&
        <Text dangerouslySetInnerHTML={{
          __html: applyVarious({
            source: monitorData?.content,
            replace: {
              '$qty': mintAmout,
              // '$price': Math.floor(mintAmout * mintPrice * 100) / 100
              '$price': mintAmout * mintPrice
            }
          })
        }} />
      }
      { type === 'mint' &&
        <Buttonbar>{ renderMintSelector() }</Buttonbar>
      }
    </Root>
  )
}


const Root = styled.div`
  position: absolute;
  left: 33%;
  top: 31.5%;
  padding: 12px;
  width: 34%;
  height: 19%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity ${during}ms ease;
  ${({ step }) => step === 'in' && css`
    opacity: 0;
  `}
  ${({ step }) => step === 'out' && css`
    opacity: 1;
  `}
`

const Heading = styled.div`
  font-size: 48px;
  font-family: 'Economica';
  color: rgba(146, 220, 243, 1);
  text-align: center;
  white-space: break-spaces;
  line-height: 1;
  ${respondTo.md} {
    font-size: 24px;
  }
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 8px;
  max-width: 60%;
  height: auto;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 48px;
  font-family: 'Economica';
  color: rgba(146, 220, 243, 1);
  text-align: center;
  white-space: break-spaces;
  line-height: 1;
  ${respondTo.md} {
    font-size: 24px;
  }

`

const Content = styled.div`
  font-size: 24px;
  font-family: 'Economica';
  color: rgba(146, 220, 243, 1);
  text-align: center;
  white-space: break-spaces;
  line-height: 1;
  ${respondTo.md} {
    font-size: 16px;
  }
`

const Text = styled.div`
  font-family: 'Economica';
  color: rgba(146, 220, 243, 1);
  text-align: center;
  white-space: break-spaces;
  line-height: 1;
  font-size: 24px;
  img {
    display: block;
    margin: 0 auto;
  }
  span {
    font-size: 36px;
  }
  ${respondTo.md} {
    font-size: 16px;
    span {
      font-size: 24px;
    }
  }
`

const Buttonbar = styled.div`
  margin-top: 12px;
  ${respondTo.md} {
    display: none;
  }
  > button {
    display: inline-block;
    margin: 0 10px;
    border: 0;
    padding: 0;
    background: transparent;
    color: rgba(146, 220, 243, 1);
    font-size: 48px;
    opacity: 0.6;
    font-family: 'Economica';
    text-align: center;
    white-space: break-spaces;
    line-height: 1;
    &.active {
      opacity: 1;
    }
  }
`

export default Monitor;