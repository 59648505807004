import React from 'react';
import styled from 'styled-components';
import Map from '../../components/Map';
import { Title, Text } from '../../components/Typography';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const MapSection = () => {
  const wording = _w('journey.map');

  function handleClickMapItem(e) {
    const map = e.currentTarget.dataset.map;
  }
  
  return (
    <Root>
      <Container>
        <Title className="title">{ wording.title }</Title>
        <Content className="content">{ wording.content }</Content>
      </Container>
      <MapWrapper>
        <Map onClickItem={handleClickMapItem} />
      </MapWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding-top: 160px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 400px;
    background-image: linear-gradient(0deg, ${colors.brightBlue} 0%, hsla(0, 0%, 100%, 0) 100%);
    z-index: -1;
    ${respondTo.md} {
      content: none;
    }
  }
  ${respondTo.md} {
    padding-top: 90px;
  }
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  text-align: center;
  ${respondTo.md} {
    width: 284px;
  }
  .title {
    margin-bottom: 24px;
  }
`

const Content = styled(Text)`
  margin-bottom: 12px;
  white-space: break-spaces;
  color: ${colors.darkBlue};
  text-align: left;
  ${respondTo.md} {
    text-align: justify;
  }
`

const ListWrapper = styled.div`
  margin-bottom: 12px;
  white-space: break-spaces;
  color: ${colors.darkBlue};
  text-align: left;
  font-size: 12px;
  ${respondTo.md} {
    text-align: justify;
  }
  .label {
    font-weight: 600;
  }
  ul {
    li {
      ${respondTo.md} {
        text-align: justify;
      }
    }
  }
`

const MapWrapper = styled.div`
  margin: 0 auto;
  margin-top: 120px;
  width: 1040px;
  max-width: 100%;
  ${respondTo.md} {
    margin-top: 24px;
    width: 400px;
    max-width: 80%;
  }
`

export default MapSection;