import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
// import { Widget } from 'react-typeform-embed';
import Dialog from '../../components/Dialog';
import BottomSection from './BottomSection';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { emailjs } from '../../constants/config'


const MailSection = () => {
  const wording = _w('journey.mail');
  const [ isSend, setIsSend ] = useState(false);
  const [ content, setContent ] = useState('');
  const [ isFocus, setIsFocus ] = useState(false);

  function handleChangeTextarea(e) {
    const value = e.currentTarget.value;
    if (value.length <= wording.max) {
      setContent(value);
    }
  }
  
  function handleBlurTextarea() {
    setIsFocus(false);
  }

  function handleFocusTextarea() {
    setIsFocus(true);
  }
  
  function handleSubmit() {
    
    const data = {
      service_id: emailjs.service_id,
      template_id: emailjs.template_id,
      user_id: emailjs.user_id,
      template_params: {
          'name': 'cozies.io',
          'message': content
      }
    };

    axios({
      method: 'post',
      url: emailjs.url,
      data: data,
      contentType: 'application/json'
    }).then(_ => {
      setIsSend(true);
    })
  }
  
  return (
    <Root>
      <Head>
        <Dialog className="dialog" content={wording.dialog} />
        <img src="/images/journey-mail-item.png" alt="" />
      </Head>
      {/* <Widget id="TUeUYhC8" height={600} /> */}
      <Card>
        <div className="title">{ wording.title }</div>
        {/* <div className="content">{ wording.content }</div> */}
        <Input focus={isFocus || content.length !== 0} disabled={isSend}>
          <textarea
            disabled={isSend}
            onBlur={handleBlurTextarea}
            onFocus={handleFocusTextarea}
            onChange={handleChangeTextarea}
            placeholder={wording.placehold}
            value={content}></textarea>
          <div className="limit">{content.length}/{wording.max}</div>
        </Input>
        <Button onClick={handleSubmit} disabled={isSend}>
          { isSend ? wording.is_send : wording.submit }
        </Button>
      </Card>
      <BottomSection />
      <Bottom>
        <img src="/images/journey-mail-bottom.png" alt="" />
      </Bottom>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  background-color: ${colors.brightBlue};
  padding-top: 60px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 120px;
    width: 100%;
    height: 1000px;
    background-image: url('/images/journey-mail-bg.png');
    background-size: 100% auto;
    ${respondTo.md} {
      bottom: 90px;
      height: 600px;
      z-index: 1;
    }
  }
  ${respondTo.md} {
    /* padding: 120px 0; */
    background-color: transparent;
  }
`

const Head = styled.div`
  position: relative;
  .dialog {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    display: block;
    margin: 0 auto;
    width: 250px;
    height: auto;
  }
`

const Card = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 34px;
  padding: 80px;
  width: 740px;
  max-width: 100%;
  text-align: center;
  background-color: ${colors.white};
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0 48px;
    text-align: left;
  }
  &:before {
    content: "";
    position: absolute;
    top: 26px;
    right: 40px;
    width: 138px;
    height: 70px;
    background-image: url('/images/journey-mail-icon.png');
    background-size: 100% auto;
    ${respondTo.md} {
      top: 80px;
      right: 24px;
    }
  }
  .title {
    margin-bottom: 24px;
    font-size: 48px;
    font-family: "Economica";
    line-height: 1;
    color: ${colors.blue};
    ${respondTo.md} {
      margin: 0 auto;
      margin-bottom: 24px;
      width: 70%;
      text-align: center;
    }
  }
  .content {
    margin-bottom: 40px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${colors.blue};
    ${respondTo.md} {
      text-align: left;
    }
  }
`

const Input = styled.div`
  position: relative;
  border-radius: 14px;
  border: 1px solid transparent;
  padding: 18px 22px;
  background-color: rgba(182, 210, 226, 0.3);
  transition: all .3s ease;
  ${({ focus }) => focus && css`
    border: 1px solid ${colors.blue};
    background-color: transparent;
  `}
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    border: 1px solid ${colors.blue};
    background-color: transparent;
  `}
  textarea {
    resize: none;
    border: 0;
    width: 100%;
    min-height: 300px;
    font-size: 12px;
    line-height: 18px;
    background-color: transparent;
    ${respondTo.md} {
      min-height: 200px;
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${colors.blue};
    }
  }
  .limit {
    position: absolute;
    right: 14px;
    bottom: 14px;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.blue};
  }
`

const Button = styled.button`
  margin-top: 40px;
  border: 0;
  border-radius: 5px;
  padding: 4px 24px;
  width: 134px;
  height: auto;
  color: ${colors.white};
  font-size: 16px;
  font-family: 'Economica';
  background-color: ${colors.blue};
  opacity: 0.6;
  text-align: center;
  transition: opacity .3s ease;
  &:hover {
    opacity: 1;
  }
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 1;
  `}
  ${respondTo.md} {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }
  .arrow {
    margin-right: 18px;
    transition: transform .3s ease;
    ${({ active }) => active && css`
      transform: rotate(90deg);
    `}
  }
`

const Bottom = styled.div`
  position: relative;
  padding-bottom: 50px;
  ${respondTo.md} {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: ${colors.brightBlue};
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 120px;
    background-image: url('/images/journey-mail-floor.svg');
    background-repeat: repeat-x;
    ${respondTo.md} {
      height: 90px;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    width: 580px;
    ${respondTo.md} {
      width: 375px;
    }
  }
`

export default MailSection;