import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const ManifestoPage = () => {
  const wording = _w('manifesto');
  
  return (
    <Root>
      <Helmet>
        <title>{ wording.meta_title }</title>
      </Helmet>
      <Background>
        <img className="line" src="/images/background-3.svg" alt="" />
        <img className="background" src="/images/manifesto-bg.png" alt="" />
      </Background>
      <Main>
        <div className="title">{ wording.title }</div>
        <div className="content">{ wording.content }</div>
        <div className="quote">{ wording.quote }</div>
        <a href={wording.more_link} target="_blank">{ wording.more }</a>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  background-color: ${colors.brightBlue};
  min-height: calc(100vh - 112px);
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.brightBlue};
  z-index: 0;
  ${respondTo.md} {
    width: auto;
    height: auto;
  }
  > .line {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    height: auto;
    transform: translateY(-50%);
    ${respondTo.md} {
      display: none;
    }
  }
  > .background {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    ${respondTo.md} {
      object-fit: initial;
      width: 100%;
      height: auto;
    }
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  > .title {
    margin-bottom: 24px;
    font-size: 90px;
    line-height: 1;
    font-family: 'Economica';
    color: ${colors.blue};
    text-align: center;
    ${respondTo.md} {
      font-size: 48px;
    }
  }
  > .content {
    margin: 0 auto;
    width: 600px;
    max-width: 90%;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    color: ${colors.darkBlue};
    font-style: italic;
    text-align: center;
    white-space: break-spaces;
    ${respondTo.md} {
      text-align: justify;
    }
  }
  > .quote {
    margin-top: 12px;
    margin-bottom: 48px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${colors.darkBlue};
  }
  > a {
    display: inline-block;
    border: 0;
    border-radius: 5px;
    margin-bottom: 60px;
    padding: 4px 12px;
    line-height: 1;
    font-family: 'Economica';
    color: ${colors.white};
    text-align: center;
    background-color: ${colors.opacityBlue};
  }
`

export default ManifestoPage;