import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const HeadingSection = () => {
  const wording = _w('train.heading');
  
  return (
    <Root>
      <Heading>
        <Background src={wording.background} />
        <Main src={wording.main} alt={wording.main_alt} />
        <Hint>
          <div className="wrap">
            <img src="/images/hint-arrow.svg" alt="" />
          </div>
        </Hint>
      </Heading>
    </Root>
  )
}


const Root = styled.div`
  position: relative;
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`
const Heading = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  ${respondTo.md} {
    height: 100vh;
  }
`

const Background = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Main = styled.img`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 60%;
  transform: translate(-50%, 25%);
  ${respondTo.md} {
    height: 100vh;
    width: auto;
  }
`

const floatAnimation = keyframes`
  from {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
`

const Hint = styled.div`
  position: absolute;
  left: 50%;
  bottom: 5vh;
  transform: translateX(-50%);
  color: ${colors.white};
  font-family: 'Economica';
  font-size: 30px;
  ${respondTo.md} {
    width: 100%;
    bottom: 75px;
    text-align: center;
  }
  .wrap {
    animation: ${floatAnimation} 3s infinite ease;
  }
  span {
    display: block;
    margin-bottom: 16px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
`


export default HeadingSection;