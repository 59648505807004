import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Monitor from './Monitor';
import { openseaUrl, discordUrl } from '../../contracts';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import useConnectWallet from '../../utils/useConnectWallet';
import { mintNFTNew } from '../../utils/mintNFT';
import { _w } from '../../utils/wordingSystem';
import { detectWhiteListEnable, detectPublicEnable } from './MintableStatus';
import { getIsSoldOut, getPublicMintPrice, getWhiteListIdentify, getWhiteListLeftQty } from '../../utils/getWhiteListIdentify';
import Dialog from '../../components/Dialog';
import { Minus, Plus } from '../../components/Icons';

const NewMintPage = () => {
  const wording = _w('setting.mint');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ leaveAmountDisplay, setLeaveAmountDisplay ] = useState(null);
  const [ isPause, setIsPause ] = useState(false);
  const [ isSoldOut, setIsSoldOut ] = useState(false);
  const [ mintPrice, setMintPrice ] = useState(0);
  const [ mintStep, setMintStep ] = useState(null);
  const [ allowMintAmout, setAllowMintAmount ] = useState(0);
  const [ whiteListIdentify, setWhiteListIdentify ] = useState(null);
  const [ mintAmout, setMintAmount ] = useState(1);
  const [ currentStatus, setCurrentStatus ] = useState('welcome');
  const { status: walletStatus, walletAddress, onConnect, isNotFound } = useConnectWallet();

  useEffect(() => {
    handleEntryStatus();
  }, [walletStatus])

  useEffect(() => {
    window.ethereum?.on('accountsChanged', function () {
      window.location.reload();
    })
  }, [walletAddress])
  
  async function handleDefinedMintableStatus() {
    setIsLoading(true);

    const { isSoldOut, alreadyMint } = await getIsSoldOut();
    setLeaveAmountDisplay(`There are ${alreadyMint} already minted.`);

    if (isSoldOut) {
      setIsSoldOut(true);
      setCurrentStatus('empty');
    }
    else {
      const whiteListIdentify = await getWhiteListIdentify();
      const isWhiteListEnable = await detectWhiteListEnable(walletAddress);
      const isPublicMintEnable = await detectPublicEnable(walletAddress);
      
      if (!isPublicMintEnable && !isWhiteListEnable && whiteListIdentify) {
        setCurrentStatus('is_whitelist');
      }
      else if (isWhiteListEnable && whiteListIdentify) {
        const { maxQuantity } = whiteListIdentify;
        const left = await getWhiteListLeftQty(maxQuantity);
        if (left === 0) {
          setCurrentStatus('already_mint');
        }
        else {
          setCurrentStatus('select_whitelist');
          setWhiteListIdentify(whiteListIdentify);
          setMintStep('whitelist');
          setMintAmount(left);
          setAllowMintAmount(left);
        }
      }
      else if (isPublicMintEnable) {
        const mintPrice = await getPublicMintPrice();
        setMintPrice(mintPrice);
        setCurrentStatus('select_public');
        setMintStep('public');
        setMintAmount(1);
        setAllowMintAmount(1);
      }
      else {
        // setIsPause(isPause);
        setCurrentStatus('error');
      }
    }
    setIsLoading(false);
  }
  
  function handleEntryStatus() {
    setTimeout(() => {
      if (walletStatus === 'connected') {
        handleDefinedMintableStatus();
      }
      else if (walletStatus === 'disconnected') {
        setCurrentStatus('unconnected');
      }
    }, 600)
  }

  function handleClickNumber(e) {
    const number = Number(e.currentTarget.dataset.number);
    setMintAmount(number);
  }

  function handleClickMinusButton() {
    setMintAmount(Math.max(mintAmout-1, 1));
  }
  function handleClickPlusButton() {
    setMintAmount(Math.min(mintAmout+1, allowMintAmout));
  }

  async function handleClickConnectButton() {
    if (isNotFound) return;
    if (walletStatus === 'disconnected') onConnect();
  }

  function handleClickLetMintButton() {
    setCurrentStatus('qty');
  }
  function handleClickBackButton() {
    if (mintStep === 'public') {
      setCurrentStatus('select_public');
    }
    else if (mintStep === 'whitelist') {
      setCurrentStatus('select_whitelist');
    }
  }
  async function handleClickOKButton() {
    if (walletStatus === 'connected') {
      const { status, message } = await mintNFTNew(whiteListIdentify, mintAmout, mintPrice);
      if (status === 'succeed') {
        setCurrentStatus('succeed');
      }
      else if (status === 'failed') {
        setCurrentStatus('failed');
      }
    };
  }
  
  return (
    <Root>
      <Background>
        <img className="desktop" src="/images/mint-bg.png" alt="" />
        <img className="mobile" src="/images/mint-bg-m.png" alt="" />
      </Background>
      <Main>
        <MainComputer show={isSoldOut} src="/images/mint-sold_out.png" alt="" />
        <MainComputer className="cover" show={!isSoldOut} src="/images/mint-main.png" alt="" />
        {/* <MainComputer className="cover" show={isPause && !isSoldOut} src="/images/mint-pause.png" alt="" /> */}
        {/* <MainComputer className="cover" show={!isSoldOut && !isPause} src="/images/mint-main.png" alt="" /> */}

        {/* { isPause &&
          <PauseDialog arrow="left" className="dialog" content={wording.out_of_order} />
        } */}

        {/* { currentStatus !== 'welcome' && */}
        { (mintStep && currentStatus !== 'welcome') &&
          <>
            <WhitelistDialog
              arrow="left"
              className="dialog"
              content={
                (mintStep === 'whitelist' && wording.whitelist_dialog) ||
                (mintStep === 'public' && wording.public_dialog)
              }
            />
            <WhitelistDialogMobile
              arrow="center"
              className="dialog"
              content={
                (mintStep === 'whitelist' && wording.whitelist_dialog) ||
                (mintStep === 'public' && wording.public_dialog)
              }
            />
          </>
        }
        <Monitor
          data={wording[currentStatus]}
          mintMax={allowMintAmout}
          mintPrice={mintPrice}
          mintAmout={mintAmout}
          clickNumber={handleClickNumber} />
        <Ticket active={currentStatus === 'succeed'}>
          <img src="/images/mint-ticket.png" alt="" />
        </Ticket>
        <ButtonBar>
          { currentStatus === 'unconnected' &&
            <Button onClick={handleClickConnectButton}>
              { !isLoading ? 'CONNECT WALLET' : 'CONNECTING...'}
            </Button>
          }
          { (currentStatus === 'select_public' || currentStatus === 'select_whitelist' ) &&
            <>
              { mintStep === 'whitelist' &&
                <Selector>
                  <button onClick={handleClickMinusButton}><Minus /></button>
                  <div className="qty">{mintAmout}</div>
                  <button onClick={handleClickPlusButton}><Plus /></button>
                </Selector>
              }
              {
                mintStep === 'public' &&
                <LeaveAmount>{ leaveAmountDisplay }</LeaveAmount>
              }
              <Button onClick={handleClickLetMintButton}>FREE MINT</Button>
            </>
          }
          { currentStatus === 'qty' &&
            <>
              <Button onClick={handleClickBackButton}>BACK</Button>
              <Button onClick={handleClickOKButton}>MINT</Button>
            </>
          }
          { currentStatus === 'succeed' &&
            <>
              {/* <Button as={Link} to="/unlockcozies">UNLOCK COZIES</Button> */}
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { currentStatus === 'failed' &&
            <>
              <Button onClick={handleClickBackButton}>TRY AGAIN</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
          { (isPause && !isSoldOut) &&
            <>
              <Button as="a" href={discordUrl} target="_blank">JOIN DISCORD</Button>
            </>
          }
          { isSoldOut &&
            <>
              <Button disabled>SOLD OUT</Button>
              <Button as="a" href={openseaUrl} target="_blank">OPENSEA</Button>
            </>
          }
        </ButtonBar>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  ${respondTo.md} {
    width: 100vw;
    overflow: hidden;
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  > img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
  .desktop { display: block; }
  .mobile { display: none; }
  ${respondTo.md} {
    .desktop { display: none; }
    .mobile { display: block; }
  }
`

const Main = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 750px;
  transform: translate(-50%, -50%);
  ${respondTo.md} {
    top: 40%;
    width: 100%;
  }
`

const MainComputer = styled.img`
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: hidden;
  &.cover {
    position: absolute;
    top: 0;
    left: 0;
  }
  ${({ show }) => show && css`
    opacity: 1;
    visibility: visible;
  `}
`

const Ticket = styled.div`
  position: absolute;
  top: 67.5%;
  left: 50%;
  width: 10%;
  transform: translateX(-50%);
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transform: translateY(-100%);
    transition: all 1s ease;
    ${({ active }) => active && css`
      transform: translateY(0%);
    `}
  }
`

const LeaveAmount = styled.div`
  margin-bottom: 24px;
  font-family: 'Economica';
  font-size: 24px;
  text-align: center;
  color: ${colors.white};
  white-space: break-spaces;
`

const Button = styled.button`
  border: 1px solid ${colors.blue};
  border-radius: 24px;
  padding: 8px;
  width: 190px;
  font-family: 'Economica';
  font-size: 24px;
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  color: ${colors.blue};
  background: ${colors.white};
  & + a, & + button {
    margin-top: 12px;
  }
  ${({ disabled }) => disabled && css`
    background: rgba(105, 167, 202, .6);
    color: ${colors.white};
    pointer-events: none;
  `}
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 10%;
  text-align: center;
  width: 100%;
  ${respondTo.md} {
    bottom: -15%;
  }
`

const Selector = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    border: 1px solid ${colors.lightBlue};
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: ${colors.white};
    color: ${colors.blue};
  }
  .qty {
    font-family: 'Economica';
    border: 1px solid ${colors.white};
    border-radius: 24px;
    width: 84px;
    height: 42px;
    padding: 7px 0;
    text-align: center;
    background: ${colors.darkBlue};
    color: ${colors.white};
    box-sizing: border-box;
  }
`

const WhitelistDialog = styled(Dialog)`
  position: absolute;
  right: 0;
  top: 30%;
  z-index: 2;
  ${respondTo.md} {
    display: none;
  }
`

const WhitelistDialogMobile = styled(Dialog)`
  display: none;
  position: absolute;
  right: 50%;
  top: 5%;
  transform: translateX(50%);
  z-index: 2;
  ${respondTo.md} {
    display: block;
  }
`

export default NewMintPage;