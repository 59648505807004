import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ToggleButton from './ToggleButton';
import { Mute, Unmute, Next, Pause, Play, Prev } from '../Icons';
import { colors } from '../../constants/colors';
import SoundIcon from '../SoundIcon';

const Player = ({ data, ...props }) => {
  const audioRef = useRef([]);
  const detectIntervalRef = useRef(null);
  const currentIndexRef = useRef(0);
  const isPlayRef = useRef(false);

  const [ isMini, setIsMini ] = useState(false);
  const [ isLoad, setIsLoad ] = useState(false);
  const [ isPlay, setIsPlay ] = useState(false);
  const [ isUnmute, setIsUnmute ] = useState(true);
  const [ currentIndex, setCurrentIndex ] = useState(0);

  useEffect(() => {
    data.forEach((item, i) => {
      const audio = new Audio(item.file);
      audio.load();
      
      audio.onloadeddata = function() {
        audioRef.current[i] = audio;
        if (audioRef.current.length === data.length) {
          setIsLoad(true);
        }
      };
      
    });
    return () => {
      clearPlayer();
    }
  }, [])

  function clearPlayer() {
    const audioList = audioRef.current;
    audioList.forEach(audio => {
      audio.currentTime = 0;
      audio.pause();
    });
    clearInterval(detectIntervalRef.current);
  }
  
  function PlayAudio(index) {
    const audioList = audioRef.current;
    setCurrentIndex(index);
    currentIndexRef.current = index;
    if (isPlayRef.current) {
      audioList[index].currentTime = 0;
      audioList[index].play();
      detectPlayEnd(audioList[index]);
    }
  }
  
  function handleClickPlay() {
    const status = !isPlayRef.current;
    setIsPlay(status);
    isPlayRef.current = status;
    const audioList = audioRef.current;
    if (status) {
      audioList[currentIndex].play();
      detectPlayEnd(audioList[currentIndex]);
    }
    else {
      audioRef.current.forEach(audio => audio.pause());
    }
  }

  function detectPlayEnd(audio) {
    clearInterval(detectIntervalRef.current);
    const detecter = setInterval(() => {
      if (audio.currentTime >= audio.duration) {
        const index = currentIndexRef.current;
        const nextIndex = index !== data.length-1 ? index+1 : 0;
        clearInterval(detectIntervalRef.current);
        PlayAudio(nextIndex);
      }
    }, 1000);
    detectIntervalRef.current = detecter;
  }

  function handleClickMute() {
    const currentIsUnmute = !isUnmute;
    
    audioRef.current.forEach(audio => {
      audio.muted = isUnmute;
    });

    setIsUnmute(currentIsUnmute);
  }
  
  function handleClickPrev() {
    if (currentIndex !== 0) {
      audioRef.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
      PlayAudio(currentIndex-1);
    }
  }
  
  function handleClickNext() {
    if (currentIndex !== data.length-1) {
      audioRef.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
      PlayAudio(currentIndex+1);
    }
  }

  function handleClickToggleButton() {
    setIsMini(!isMini);
  }

  return (
    <Root {...props}>
      <DisplayButton size={ isMini ? 'min' : 'max'} onClick={handleClickToggleButton} />
      <Body mini={isMini}>
        <img src="/images/player.svg" alt="" />
        <Wrapper>
          <Screen>
            <div className="title">Cozies Tunes</div>
            <ul className="list">
              { data.map((item, i) =>
                <li key={i}>
                  <span>{item.name}</span>
                  { currentIndex === i &&
                    <SoundIcon status={isPlay} />
                  }
                </li>
              ) }
            </ul>
          </Screen>
          <Controller enable={isLoad}>
            <Button className="mute" onClick={handleClickMute}>
              { isUnmute ? <Unmute /> : <Mute /> }
            </Button>
            
            <Button disable={currentIndex === data.length-1} 
              className="next"
              onClick={handleClickNext}>
              <Next />
            </Button>
              
            <Button disable={currentIndex === 0}
              className="prev"
              onClick={handleClickPrev}>
              <Prev />
            </Button>

            <Button className="play" onClick={handleClickPlay}>
              { isPlay ? <Pause /> : <Play /> }
            </Button>
          </Controller>
        </Wrapper>
      </Body>
    </Root>
  )
}


const Root = styled.div`
  position: relative;
`

const DisplayButton = styled(ToggleButton)`
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: 12px;
  transition: all .6s ease;
  z-index: 1;
  ${({ size }) => size === 'min' && css`
    margin-bottom: 0;
    bottom: 0;
  `}
`

const Body = styled.div`
  position: relative;
  max-height: 100vh;
  opacity: 1;
  transition: opacity .6s ease;
  ${({ mini }) => mini && css`
    opacity: 0;
    pointer-events: none;
  `}
`
const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  padding-left: 16px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
`

const Screen = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  overflow: hidden;
  border: 1px solid ${colors.blue};
  min-height: 75px;
  background: #779CB0;
  box-sizing: border-box;
  .title {
    margin-bottom: 5px;
    line-height: 1;
    box-sizing: border-box;
    font-size: 12px;
    text-align: center;
    color: ${colors.white};
    transform: scale(0.8);
    white-space: nowrap;
  }
  .list {
    line-height: 1;
    font-family: "Economica";
    font-size: 12px;
    color: ${colors.white};
    > li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px;
      }
    }
  }
`

const Controller = styled.div`
  position: relative;
  margin-top: 4px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid ${colors.blue};
  background-color: ${colors.white};
  ${({ enable }) => !enable && css`
    pointer-events: none;
  `}
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid ${colors.blue};
    border-radius: 50%;
    width: 40%;
    height: 40%;
    background-color: ${colors.lightBlue};
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`

const Button = styled.button`
  position: absolute;
  border: 0;
  background-color: transparent;
  color: ${colors.blue};
  ${({ close }) => close && css`
    opacity: .5;
  `}
  ${({ disable }) => disable && css`
    opacity: .5;
    cursor: not-allowed;
  `}
  &.mute {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &.next {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
  &.prev {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
  &.play {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export default Player;